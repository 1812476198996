import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/swiper-bundle.css"
import { serviceList } from '../../../data/servicedetails/service';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ServiceCarousel = () =>{

    const {t} = useTranslation()
    return (
        <section className="tp-service-area pt-120 pb-90" style={{background:"#F6F6F9"}}>
            <div className="container">
              <div className="row">
                  <div className="col-lg-6 col-md-8">
                     <div className="tp-service-title-wrapper mb-40">
                        <span className="tp-section-title-pre">{t("textOurServices")}</span>
                        <h3 className="tp-section-title" >{t("textServdescp")}</h3>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-4">
                     <div className="tp-service-nav text-end">
                        <button type="button" className="service-button-prev-1"><i className="fa-regular fa-arrow-left"></i>
                        </button>
                        <button type="button" className="service-button-next-1"><i className="fa-regular fa-arrow-right"></i>
                        </button>
                     </div>
                  </div>
                  <Swiper  
                    id="main"
                    autoplay={{delay:5000}}
                    loop={true}
                    tag='section'
                    wrapperTag='ul'
                    effect='fade'
                    breakpoints={{
                        '1700':{
                            slidesPerView:3,
                        },
                        '1400':{
                            slidesPerView:3,
                        },
                        '1200':{
                            slidesPerView:3,
                        },
                        '767': {
                            slidesPerView:2,
                        },
                        '576': {
                            slidesPerView:1,
                        },
                        '0': {
                            slidesPerView:1,
                        },

                    }}
                    navigation = {{
                        nextEl: ".service-button-next-1",
                        prevEl: ".service-button-prev-1"
                    }}
                    className='tp-service-active wow fadeInUp'
                    spaceBetween={10}
                    slidesPerView={3}>

                        {serviceList.map((service)=>(
                            <SwiperSlide className="swiper-slide wow slideInUp" key={service.id} style={{WebkitAnimationDuration:"2s",MozAnimationDuration:"3s",animationDuration:"3s"}}>
                                <div className="tp-service-item-wrapper p-relative mb-30 mt-40">
                                 <div className="tp-service-item-icon">
                                    <span>
                                       <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d={service.icone1} fill="white"/>
                                          <path d={service.icone2} fill="white"/>
                                       </svg>
                                    </span>
                                 </div>
                                 <div className="tp-service-item-content">
                                    {/*<h4 className="tp-service-item-title" onClick={()=>{navigate("/service_details", {state:{service:service, serviceList:serviceList}})}}>
                                        <Link to={{pathname:"/service_details", state:{service:service}}} >{service.title}</Link></h4>*/}
                                    <h4 className="tp-service-item-title">
                                        <Link to={`/service_details/${service.id}`}>{t(`${service.title}`)}</Link></h4>
                                    <p align="justify">{t(`${service.resume}`)}</p>
                                 </div>
                                 <div className="tp-service-item-thumb">
                                    {/*<Link to={{pathname:"/service_details", state:{service:service}}} ><img src={service.image0} alt=""/></Link>*/}
                                    <Link to={`/service_details/${service.id}`} ><img src={service.image0} alt=""/></Link>
                                 </div>
                              </div>
                            </SwiperSlide>
                        ))}
                  </Swiper>
                </div>
            </div>
        </section>
    )
}

export default ServiceCarousel;