export const DEFAULT_PAGE_COUNT = 25;

export const DEFAULT_IMAGE = "/favicon.ico";
export const DEFAULT_PROFILE = "/assets/images/avatar.png";
export const DEFAULT_LOGO = "/assets/img/logo/logo_small.png";
export const VISA_LOGO = "/assets/img/payments/visa.png";
export const PAYPAL_LOGO = "/assets/images/paypal.png";
export const MASTERCARD_LOGO = "/assets/img/payments/master-card.png";
export const VERVE_LOGO = "/assets/img/payments/verve_.png";
export const OM_LOGO = "/assets/img/payments/orange-money.png";
export const MOMO_LOGO = "/assets/img/payments/mtn-money.png";
export const POWER_BI_TRAINING = "/assets/img/payments/power_bi.png";
export const CHECK_MARK_SVG = "/assets/svgs/checkmark.svg"
export const GA_TRACKING_ID = process.env.REACT_APP_TRACKING_ID