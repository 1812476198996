export const en = {
    textHome: "Home",
    textPrivacyPolicy: "Privacy Policy",
    textAboutUs: "About Us",
    textService: "Services",
    textTeam: "Team",
    textBlog: "Blog",
    textContact: "Contact",
    textLngFrench: "French",
    textLngEnglish: "English",
    textTime: "8AM - 5PM",
    textDay:"Mon - Fri: ",
    textCall: "Requesting A Call:",
    textConsultation:"Booking Consultation",
    textStart:"Get Starrted",
    textHelp:"Need help ?",
    textHero11: "Your One-Stop Solution for Analytics and Financial Software",
    textHero12:"Take a step ahead through data-driven decisions with B. K. Research & Analytics.",
    textHero21: "Make Informed Decisions with Our CR²-SME",
    textHero22:"Our cutting-edge rating application combines hard and soft data to give you an accurate picture of ",
    textHero23: "the creditworthiness and growth potential of small and medium-sized businesses.",
    textHero31: "Unleash the Power of Data with Our Analytics Services",
    textHero32:"From Market Basket Analysis to Sentiment Analysis to Business Intelligence solutions, our team of ",
    textHero33: "experts will work closely with you to uncover hidden opportunities and drive growth.",
    textAbout:"ABOUT US",
    textAbdescp: "B.K. Research & Analytics is a startup based in Yaoundé, Cameroon. We help small and medium-sized enterprises and financial institutions to process structured and unstructured data.",
    textAbserv1:"Credit Risk Rating of SME Saas",
    textAbserv2:"Financial Forecasting Saas",
    textAbserv3:"Business Intelligence",
    textAbserv4:"Analytics Services",
    textOurServices: "Our Services",
    textServdescp: "Services and applications that matters.",
    textCreditrisk:{
        title:"Credit Risk Rating - SME",
        resume:"With our cutting-edge rating application, you can confidently assess the credit-worthiness and growth potential of SME.",
        subTitle1:"Make Informed Decisions with Our SMEs Rating Application ",
        description1:"Are you a bank or a financial institution looking for a reliable and affordable way to assess financial performance and creditworthiness of your small or medium-sized enterprises (SME) customers? if yes, then you need our application for financial rating of SMEs.",
        description2:"Our application is a web-based tool that uses advanced algorithms and data analysis to evaluate SME’s financial situation and generate a comprehensive report. The report includes a financial rating, which is a letter that reflects financial health and risk level of SME.",
        subSection1:[
            {
            title:"Analysis Data",
            description:"CR²-SME integrate data from two sources, that are financial statements (hard data) and market information (soft-data).",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Automation",
            description:"CR²-SME allow to automate complex procedures such as credit risk assessment, loan pricing, and application decision-making.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Benefits",
        benefit1:"With CR²-SME, Bank can improve the efficiency and accuracy of credit risk assessment, reduce the cost and time of credit processing, and enhance the portfolio management and monitoring.",
        benefit2:"This allow more credit opportunities for SMEs, with lower interest rates, faster and simpler loan applications.",
        other:"This Saas has been developed taking into consideration specificities of OHADA SME zone. It has been done by Africans for Africans.",
        image0:"/assets/img/service/credit_1.jpg",
        image1:"/assets/img/service/service-details/credit_2.jpg",
        image2:"/assets/img/service/service-details/credit_3.jpg",
        characteristics:[
            " Soft data included ",
            " Financial Statements uploaded ",
            " More than 150 ratios calculated "
        ],
        brochure:"",
        subSection2:[
            {
            title:"Credit Risk Rating - Basic",
            input:"Only Financial Data",
            output:"Simplified Rating Report",
            Onboarding:"100 000 CFA",
            price_transaction:"60 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Credit Risk Rating - Professionnal",
            input:"Financial Data, Soft Data",
            output:"Simplified Rating Report",
            Onboarding:"100 000 CFA",
            price_transaction:"75 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Credit Risk Rating - Corporate",
            input:"Financial Data, Soft Data",
            output:"Full Detailed Rating Report, Word+Excel Report",
            Onboarding:"100 000 CFA",
            price_transaction:"90 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Credit Risk Rating - Advanced",
            input:"Financial Data, Soft Data",
            output:"All Corporate characteristics + API",
            Onboarding:"100 000 CFA",
            price_transaction:"110 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            }
        ]
    },
    textFinancialfo:{
        title:"Financial Forecasting - SME",
        resume:"Our advanced financial forecasting applica-tion empowers you to project future financial outcomes accurately.",
        subTitle1:"Accurate Financial Forecasting for Sustainable Growth",
        description1:"Do you want to plan ahead and make better financial decisions for your small or medium-sized enterprise (SME)? If so, you need our application that makes financial forecasting of financial statements. Our application is a power-ful tool that analyze your past and present financial data and generate accurate and reliable forecasts for your future performance (income statement, balance sheet, cash flow statement, and key financial ratios).",
        description2:"You can customize your forecasts by adjusting various parameters, such as growth rate, expenses, taxes, and capital structure. Our application will help you identify your strengths and weaknesses, optimize your cash flow, plan your budget, evaluate your profitability, and improve your financial health.",
        subSection1:[
            {
            title:"Easy to use",
            description:"You don't need any accounting or finan-cial expertise to use our application. You just need to upload your financial state-ments [in OHADA reference, MS Excel File] and our application will do the rest.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Secure and confidential",
            description:"We use encryption, authentication, and backup systems to protect your data from unauthori-zed access, loss, or damage. We also respect your privacy and do not share your data with any third parties without your consent.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }//,
            // {
            //   title:"Interactive and visual",
            //   description:". You can view your forecasts in tables, charts, or graphs. You can also compare your forecasts with different scenarios, benchmarks, or industry standards. You can also export or print your forecasts for further analysis or presentation.",
            //   image:"/assets/img/service/service-details/icon-3.svg"
            // }
        ],
        benefit:"Benefits",
        benefit1:"Financial forecasting application provide several bene-fits to SMEs. These include better decision-making, cash flow management improvement, efficiency increasing, better access to funding and risk management improvement.",
        benefit2:"By adopting this application, SMEs can improve their financial performance, reduce risk and position themselves for long-term success.",
        other:"Whether you need to secure a loan, attract investors, or manage your business more efficiently, our application will provide you with the insights and guidance you need.",
        image0:"/assets/img/service/financial_forecasting_1.jpg",
        image1:"/assets/img/service/service-details/financial_forecasting_2.jpg",
        image2:"/assets/img/service/service-details/financial_forecasting_3.jpg",
        characteristics:[
            " Easy to use ",
            " Interactive and Visual ",
            " Secure and Confidential "
        ],
        brochure:"",
        subSection2:[
            {
            title:"Financial Forecasting - Professionnal",
            input:"Financial Data",
            output:"Simplified Forecasting Report, Simplified Analysis Report, PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"230 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Financial Forecasting - Corporate",
            input:"Financial Data",
            output:"Full Detailed Forecasting Report, Full Detailed Analysis Report, Word + Excel + PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"300 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            }
        ]

    },
    textCapitalbud:{
        title:"Capital Budgeting - SME",
        resume:"By introducing uncertainty, this application allows you to simulate different scenarios, assess risk, and refine your decisions.",
        subTitle1:"Make Smarter Investment Decisions with Our Capital Budgeting Application",
        description1:"Are you looking for a way to evaluate and compare different investment options for your business? Do you want to know which projects will give you the best return on your money? Do you need a simple and reliable tool to help you plan your capital budget?",
        description2:"If you answered yes to any of these questions, then you need our capital budgeting application. Our capital budgeting application incorporates random variables into the model, allowing you to simulate different scenarios and make informed decisions based on accurate projections.",
        subSection1:[
            {
            title:"Various Methods",
            description:"The application can perform different capital budgeting methods, such as net present value, internal rate of return, payback period, and profitability index, to evaluate and compare the investment options.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Scenarios and Sensitivity",
            description:"The application can create and compare different scenarios and perform sensitivity analysis to see how the results change with different variables and parameters.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Benefits",
        benefit1:"The application helps the user make better and more informed investment decisions based on data and analysis, rather than intuition or guesswork.",
        benefit2:"The application saves the user time and money by automating and simplifying the capital budgeting process, reducing errors and mistakes, and providing accurate and reliable calculations.",
        other:"Don’t miss this opportunity to improve your capital budgeting process and grow your business. Try our application today and see the difference.",
        image0:"/assets/img/service/capital_budgeting_1.jpg",
        image1:"/assets/img/service/service-details/capital_budgeting_2.jpg",
        image2:"/assets/img/service/service-details/capital_budgeting_3.jpg",
        characteristics:[
            "Easy to use interface",
            "Accurate and reliable calculations",
            "Customizable scenarios and parameters"
        ],
        brochure:"",
        subSection2:[
            {
            title:"Capital Budgeting - Professionnal",
            input:"Financial Data",
            output:"Simplified Budgeting Report, Simplified Analysis Report, PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"230 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Capital Budgeting - Corporate",
            input:"Financial Data",
            output:"Full Detailed Budgeting Report, Full Detailed Analysis Report, Word + Excel + PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"300 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            }
        ]
    },
    textMarketbas:{
        title:"Market Basket Analysis",
        resume:"By analyising your transactions database, we help you profile your customers and increase your revenue.",
        subTitle1:"Optimize Your Sales and Marketing Strategies",
        description1:"Market Basket Analysis is a modelling approach that is based on the notion that if you buy one set of things, you are more (or less) likely to buy another set of items. It enables us to determine customer purchasing behaviour and influence choices during the purchase process. ",
        description2:"Market Basket Analysis seeks to find relationships between the items in a basket (spatial), and between successive baskets when time data is available (temporal). It provides clues as to what a customer might have bought if the idea had occurred to them.",
        subSection1:[
            {
            title:"Customer Experience",
            description:"The MBA reduces the waiting time for the purchase of items and guarantees their availability through improved stock management.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Revenue Optimization",
            description:"The MBA help boost sales and ROI by designing effective promotions cross-selling opportunities and personalized recommendations. ", //It helps optimize product placement by arranging products that are frequently bought together.
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Benefits",
        benefit1:"The MBA help boost sales and ROI by designing effective promotions cross-selling opportunities and personalized recommendations. It helps optimize product placement by arranging products that are frequently bought together. ", //It also helps reduce cost.
        benefit2:"This allows enterprise to Improve customer satisfaction and overall business performance by making informed decisions.",//"The interest of Market Basket Analysis is to find items that buyers desire to buy and to increase sales by better understanding customer purchasing patterns. ", 
        other:"When using MBA, the extent of the increase in sales depends on the price sensitivity of customers and the level of saturation of affinities. This increase could vary between 0.5% and 10%.",
        image0:"/assets/img/service/basket_1.jpg",
        image1:"/assets/img/service/service-details/basket_2.jpg",
        image2:"/assets/img/service/service-details/basket_3.jpg",
        characteristics:[
            "Pricing",
            "Building customer loyalty",
            "Cross-sell and up-sell tactics"
        ],
        brochure:"",
        // subSection2:[]
    },
    textSentiana:{
        title:"Sentiment Analysis",
        resume:"What do your customers say about your company? We can help you get sentiment insights of your customers.",
        subTitle1:"Know Your Customers Thoughts.",
        description1:"Sentiment analysis is a technique that uses natural language processing (NLP) to determine whether a piece of text is positive, negative or neutral. It can help businesses understand customer feedback and opinions.",
        description2:"Sentiment analysis help you reduce customer churn by addressing negative feedback and improving retention. ",
        subSection1:[
            {
            title:"Social media Monitoring",
            description:"We can track the sentiment of users towards a brand, product, or topic on social media platforms.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Product Analysis",
            description:"It helps evaluates the strengths and weaknesses of a product based on customer reviews.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Benefits",
        benefit1:"Sentiment Analysis can help identify strengths and weaknesses of your product or service based on customer feedback.  ", //It allows handle multiple customers simultaneously and prioritize the most urgent or negative.
        benefit2:"It can help you understand market trends and customer preferences and develop an impactful growth strategy. ",
        other:"The interest of sentiment analysis is to understand the emotions and opinions of people from their text. It can help you gain insights into customer feedback, market trends, product performance, and more.",
        image0:"/assets/img/service/sentiment_1.jpg",
        image1:"/assets/img/service/service-details/sentiment_2.jpg",
        image2:"/assets/img/service/service-details/sentiment_3.jpg",
        characteristics:[
            "Brand monitoring",
            "Reputation management",
            "Voice of the customer"
        ],
        brochure:"",
        // subSection2:[]
    },
    textAnalytics:{
        title:"Analytics Services",
        resume:"Whether it's implementing BI solutions or conducting machine learning project, we have the expertise to drive your business ...",
        subTitle1:"Unleash the Power of Data",
        description1:"The Data analytics is a service through which we help businesses unlock insights from their data and make better decisions. Data analytics services can involve various elements such as data warehousing, data visualization, data science, big data, etc",
        description2:"",
        subSection1:[
            {
            title:"Operation Optimization",
            description:"Optimizing workflows to improve employee productivity and engagement. Monitoring data to enhance cybersecurity. Streamlining operations to be more efficient.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Customer Experience",
            description:"Tracking consumer behaviors to enrich customer experiences. Monitoring market trends to launch new products and services. Measuring performance of marketing campaigns.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Benefits",
        benefit1:"You may personalise the customer experience using Data Analytics by adapting your marketing messages and services to each unique consumer. Threats to your business, such as fraud, cyberattacks, and compliance issues, may be avoided or mitigated.",
        benefit2:"",//"Data analytics may assist businesses in identifying new prospects, launching new products or services, or improving client loyalty, all of which lead to increased revenue.",
        other:"Companies who place high emphasis on data see an average annual revenue increase of 5.32% due to better data use.",
        image0:"/assets/img/service/analytics_1.jpg",
        image1:"/assets/img/service/service-details/analytics_2.jpg",
        image2:"/assets/img/service/service-details/analytics_3.jpg",
        characteristics:[
            "Monitoring market ",
            "Streamlining operations ",
            "Tracking consumer behaviors "
        ],
        brochure:"",
        // subSection2:[
        // ]
    },
    textProcess:"Our work process",
    textProtitle:"This is a key factor of our success.",
    textProitem11:"Orientation",
    textProitem12:"This phase involves making the first contact with the client, identifying the desired outcomes, and using a force-field diagnosis to assess the current situation and the factors that influence it.",
    textProitem21:"Research and Solution",
    textProitem22:"This phase involves projecting the goals, designing and conducting the research, analysing the data, developing the solution, and presenting the proposal to the client.",
    textProitem31:"Implementation",
    textProitem32:"This phase involves taking successful action, monitoring and evaluating the results, and designing continuity supports to ensure sustainability.",
    textPropro:"Project Completed",
    textAsktitle0:"ask anything",
    textAsktitle1:"You can contact",
    textAsktitle2:"us for any question",
    textAskdesp1:"If you have any question or feedback, please feel free to contact us. We are always happy to hear from you and help you with your needs. You can ",
    textAskad:"email",
    textAskdesp2:" us or dial +237 696 888 241. We look forward to hearing from you soon.",
    textAsksat:" Happy Clients",
    textAskfaq:{
        que1:"What is business consulting?",
        ans1:"Business consulting is a service provided by professionals who offer expert advice and guidance to organizations that are looking to improve their business processes, their strategies, their operations, and their profitability.",
        que2:"What types of businesses typically use consulting services?",
        ans2:"Organizations of all sizes utilize consulting services, with big corporations often leading the way. However, SMEs require these services more than most, yet often fail to request them.",
        que3:"What are the benefits of using a business consulting service?",
        ans3:"Organizations of all sizes utilize consulting services, with big corporations often leading the way. However, SMEs require these services more than most, yet often fail to request them.",
        que4:"How much do consulting services cost?",
        ans4:"The cost of consulting services depends on several factors, such as the type, duration, and location of the service, as well as the experience and expertise of the consultant.",
    },
    textOurTeam:{
        title:"our team ",
        slogpart1:"The Right People is our Most",
        slogpart2:"Important Asset"
    },
    textBlog:{
        part1:"Building Better Businesses",
        part2:"Together"
    },
    textFaq1:{
        que1:"What is business consulting?",
        ans1:"Business consulting is a service provided by professionals who offer expert advice and guidance to organizations that are looking to improve their business processes, their strategies, their operations, and their profitability.",
        que2:"What types of businesses typically use consulting services?",
        ans2:"Organizations of all sizes utilize consulting services, with big corporations often leading the way. However, SMEs require these services more than most, yet often fail to request them.",
        que3:"What are the benefits of using a business consulting service?",
        ans3:"Organizations of all sizes utilize consulting services, with big corporations often leading the way. However, SMEs require these services more than most, yet often fail to request them.",
        que4:"How much do consulting services cost?",
        ans4:"The cost of consulting services depends on several factors, such as the type, duration, and location of the service, as well as the experience and expertise of the consultant.",
    },
    textFaq2:{
        que1:"What is CR²-SME?",
        ans1:"CR²-SME is an online tool that evaluates credit risk and rates SMEs by evaluating their financial statements and social characteristics before granting them credit.",
        que2:"What types of businesses typically use CR²-SME?",
        ans2:"CR²-SME is a service available to financial institutions. It is particularly suited to banks and microfinance institutions.",
        que3:"What are the benefits of using CR²-SME?",
        ans3:"CR²-SME can assist a financial institution in evaluating and improving its financial soundness by measuring performance based on six factors: capital sufficiency, asset quality, management, earnings, liquidity, and market risk sensitivity.  It can also assist a financial institution in complying with consumer protection laws and regulations by examining its compliance with fair lending, privacy, and disclosure obligations.",
        que4:"How much do CR²-SME cost?",
        ans4:"For more information about the price, follow the ",
        ans5:"link",
        ans6:", navigate to the bottom.  ",
    },
    textFaq3:{
        que1:"What is Market Basket Analysis?",
        ans1:"Market Basket Analysis is a modelling technique based on the theory that if you buy one group of items, you are more (or less) likely to buy another group of items.",
        que2:"What types of businesses typically use Market Basket Analysis?",
        ans2:"Market basket analysis is typically used by retailers, such as supermarkets, online stores, and restaurants, but it can also be applied to other industries, such as banking, healthcare, and education.",
        que3:"What are the benefits of using Market Basket Analysis?",
        ans3:"Market Basket Analysis can help businesses understand customer behaviour and increase sales through cross-selling and up-selling opportunities.",
        que4:"How much do Market Basket Analysis cost?",
        ans4:"For more information about the price, please contact us at ",
        ans5:"contact@bkresearchandanalytics.com",
    },
    textFaq4:{
        que1:"What is Financial Forecasting ?",
        ans1:"Financial forecasting refers to financial projections performed to facilitate any decision-making relevant for determining future business performance.",
        que2:"What types of businesses typically use Financial Forecasting Software ?",
        ans2:"Businesses that typically use financial forecasting software include technology companies, services companies, and organisations of all size that rely on a team to gather data, determine market trends and shifts.",
        que3:"What are the benefits of using Financial Forecasting Software ?",
        ans3:"It offers several benefits to small and medium-sized enterprises (SMEs) such as improved decision-making, enhanced cash flow management, increased profitability, facilitated business planning. However, its effectiveness depends on the quality of data input and the accuracy of assumptions made during the process.",
        que4:"How much do Financial Forecast Service cost?",
        ans4:"For more information about the price, follow this ",
        ans5:"link",
        ans6:", navigate to the bottom.",
    },
    textCont:{
        title:"Get in touch with us!",
        subtitle:"We are here at your service. What can we assist you with?",
        name:"Your Name",
        email:"Email Here",
        message:"Message here",
        submit:"Submit",
        address:"Address",
        mail:"Mail Us",
        tel:"Telephone"
    },
    textBl:{
        cat:"Categories",
        it1:"Artificial Intelligence",
        it2:"Finance",
        it3:"Business",
        rec:"Recent Post",
        wk:"Work with us",
        txt:"Send us your candidature at contact@bkresearchandanalytics.com"
    },
    txtCountry:{
        "AF": "Afghanistan",
        "AX": "Aland Islands",
        "AL": "Albania",
        "DZ": "Algeria",
        "AS": "American Samoa",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua and Barbuda",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaijan",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgium",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivia",
        "BQ": "Bonaire, Saint Eustatius and Saba",
        "BA": "Bosnia and Herzegovina",
        "BW": "Botswana",
        "BV": "Bouvet Island",
        "BR": "Brazil",
        "IO": "British Indian Ocean Territory",
        "VG": "British Virgin Islands",
        "BN": "Brunei",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "CV": "Cabo Verde",
        "KH": "Cambodia",
        "CM": "Cameroon",
        "CA": "Canada",
        "KY": "Cayman Islands",
        "CF": "Central African Republic",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CX": "Christmas Island",
        "CC": "Cocos Islands",
        "CO": "Colombia",
        "KM": "Comoros",
        "CK": "Cook Islands",
        "CR": "Costa Rica",
        "HR": "Croatia",
        "CU": "Cuba",
        "CW": "Curacao",
        "CY": "Cyprus",
        "CZ": "Czechia",
        "CD": "Democratic Republic of the Congo",
        "DK": "Denmark",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "EC": "Ecuador",
        "EG": "Egypt",
        "SV": "El Salvador",
        "GQ": "Equatorial Guinea",
        "ER": "Eritrea",
        "EE": "Estonia",
        "SZ": "Eswatini",
        "ET": "Ethiopia",
        "FK": "Falkland Islands",
        "FO": "Faroe Islands",
        "FJ": "Fiji",
        "FI": "Finland",
        "FR": "France",
        "GF": "French Guiana",
        "PF": "French Polynesia",
        "TF": "French Southern Territories",
        "GA": "Gabon",
        "GM": "Gambia",
        "GE": "Georgia",
        "DE": "Germany",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Greece",
        "GL": "Greenland",
        "GD": "Grenada",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard Island and McDonald Islands",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hungary",
        "IS": "Iceland",
        "IN": "India",
        "ID": "Indonesia",
        "IR": "Iran",
        "IQ": "Iraq",
        "IE": "Ireland",
        "IM": "Isle of Man",
        "IL": "Israel",
        "IT": "Italy",
        "CI": "Ivory Coast",
        "JM": "Jamaica",
        "JP": "Japan",
        "JE": "Jersey",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "XK": "Kosovo",
        "KW": "Kuwait",
        "KG": "Kyrgyzstan",
        "LA": "Laos",
        "LV": "Latvia",
        "LB": "Lebanon",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libya",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "MO": "Macao",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malta",
        "MH": "Marshall Islands",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia",
        "MD": "Moldova",
        "MC": "Monaco",
        "MN": "Mongolia",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MA": "Morocco",
        "MZ": "Mozambique",
        "MM": "Myanmar",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "AN": "Netherlands Antilles",
        "NC": "New Caledonia",
        "NZ": "New Zealand",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk Island",
        "MP": "Northern Mariana Islands",
        "KP": "North Korea",
        "MK": "North Macedonia",
        "NO": "Norway",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palestinian Territory",
        "PA": "Panama",
        "PG": "Papua New Guinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippines",
        "PN": "Pitcairn",
        "PL": "Poland",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "CG": "Republic of the Congo",
        "RE": "Reunion",
        "RO": "Romania",
        "RU": "Russia",
        "RW": "Rwanda",
        "BL": "Saint Barthelemy",
        "SH": "Saint Helena",
        "KN": "Saint Kitts and Nevis",
        "LC": "Saint Lucia",
        "MF": "Saint Martin",
        "PM": "Saint Pierre and Miquelon",
        "VC": "Saint Vincent and the Grenadines",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "Sao Tome and Principe",
        "SA": "Saudi Arabia",
        "SN": "Senegal",
        "RS": "Serbia",
        "CS": "Serbia and Montenegro",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SX": "Sint Maarten",
        "SK": "Slovakia",
        "SI": "Slovenia",
        "SB": "Solomon Islands",
        "SO": "Somalia",
        "ZA": "South Africa",
        "GS": "South Georgia and the South Sandwich Islands",
        "KR": "South Korea",
        "SS": "South Sudan",
        "ES": "Spain",
        "LK": "Sri Lanka",
        "SD": "Sudan",
        "SR": "Suriname",
        "SJ": "Svalbard and Jan Mayen",
        "SE": "Sweden",
        "CH": "Switzerland",
        "SY": "Syria",
        "TW": "Taiwan",
        "TJ": "Tajikistan",
        "TZ": "Tanzania",
        "TH": "Thailand",
        "NL": "The Netherlands",
        "TL": "Timor Leste",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad and Tobago",
        "TN": "Tunisia",
        "TR": "Turkey",
        "TM": "Turkmenistan",
        "TC": "Turks and Caicos Islands",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "AE": "United Arab Emirates",
        "GB": "United Kingdom",
        "US": "United States",
        "UM": "United States Minor Outlying Islands",
        "UY": "Uruguay",
        "VI": "U.S. Virgin Islands",
        "UZ": "Uzbekistan",
        "VU": "Vanuatu",
        "VA": "Vatican",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "WF": "Wallis and Futuna",
        "EH": "Western Sahara",
        "YE": "Yemen",
        "ZM": "Zambia",
        "ZW": "Zimbabwe"
    },
    POWER_BI:{
        title: "Power BI Training",
        desc: "Learn and aquire data analytics skills using Microsoft Power BI"
    },
    FORM: {
        firstName: "First Name",
        lastName: "Last Name",
        profession: "Profession",
        email: "Professionnal Email Address",
        phone: "Phone Number",
        terms: "Terms",
        accept: "Accept Our",
        privacy: "Privacy Polycies",
        savenPay: "Save and pay",
        savenTA: "Save and schedule appointment",
        appointmentMSG:"In order to better adapt to your schedule, our team will contact you from 7.00 pm, 24 hours after you have filled in the form.",
        payNum: "Payment Number (Without Country code)",
        country: "Country",
        paymentDesc:"Please confirm your payment method to finalize registration.",
        processPay: "Process",
        priceBT: "Price Before Tax",
        vat:"V.A.T",
        netToPay: "Net to Pay",
        success:"Your payment has been successfully processed. You will receive your invoice by email!",
        success_:"Registration successfull. You will contacted in a short while!",
        errorReg:"Registration Failed. Check Your informations and if the problem persists, try again later",
        errorPay: "Payment Failed.  Check Your informations and if the problem persists, try again later",
        noMobileP: "Only card payments are avalaible for other OHADA countries",
        paymentInit: "Payment initiated Please check your phone"
    },
    FORMERROR:{
        firstName: "Povide Your first Name",
        profession: "Provide Your profession",
        email: "Provide A valide email address",
        phone: "Provide a Valid Phone Number",
        terms: "Need To reed and agree Our terms",
        country: "Select Your Country",
    },
    CONJUCTION:{
        our: "our",
        our_: "our",
        and: "And",
    },
    TERMS_CONDITIONS: {
        title:"B.K. Research & Analytics Terms and Conditions",
        lastUpdate: "Last updated: June 21, 2024",
        section1: "By accessing this website we assume you accept these terms and conditions. Do not continue to use B. K. Research & Analytics if you do not agree to take all of the terms and conditions stated on this page.",
        section2: "The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: \"Client\", \"You\" and \"Your\" refers to you, the person log on this website and compliant to the Company’s terms and conditions. \"The Company\", \"Ourselves\", \"We\", \"Our\" and \"Us\", refers to our Company. \"Party\", \"Parties\", or \"Us\", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.",
        cookies1: "We employ the use of cookies. By accessing B. K. Research & Analytics, you agreed to use cookies in agreement with the B. K. Research & Analytics's Privacy Policy.",
        cookies2: "Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.",
        pluginsTitle: "Plugins on our website",
        pluginsDesc: "On our website, we use social network plugins from the social networks listed below:",
        pluginsX:"X (ex Twitter)",
        pluginsX1: "Our website integrates functions of the Twitter service. Ces fonctions sont proposées par Twitter Inc., Twitter, Inc. 1355 Market St, Suite 900, San Francisco, CA 94103, USA. By using Twitter and the \"Re-tweet\" function, the websites you visit are linked to your Twitter account and published for other users. In doing so, data is transmitted to Twitter. As the provider of these pages, we draw your attention to the fact that we have no knowledge of the information provided nor of its use by Twitter. Further information can be found in Twitter's data protection declaration at the following address: http://twitter.com/privacy. <br/> On Twitter, you can change the data protection settings on your account at : http://twitter.com/account/settings.<br/> To prevent Twitter from collecting data when you visit our website, please log out of Twitter before your visit. To prevent Twitter from accessing your data in general on other websites, you can exclude Twitter plugins using an add-on for your browser. (for ex. « Twitter-Blocker », https://disconnect.me).",
        pluginsLk: "LinkedIn",
        pluginsLk1: "On our website, plugins of the social network LinkedIn are integrated. The provider is LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. By clicking on the LinkedIn button, a connection is established with the servers of LinkedIn. The content of the plugin is transmitted directly by LinkedIn to your browser and is integrated by it into the website. Thus, the information that you have visited our site is transmitted to LinkedIn. If you are logged into your LinkedIn account, by clicking on the LinkedIn button, your visit to our site can be attributed directly to your profile. Even if you do not have a profile, it is not possible to exclude that your IP address will be recorded by LinkedIn.<br/> As the provider of the pages, we would like to point out that we have no knowledge of the information provided or how it is used by LinkedIn. You can find more information about LinkedIn's data protection statement at https://www.linkedin.com/legal/privacy-policy",
        pluginsInsta: "Instagram",
        pluginsInsta1: "On our website is the social button used by Instagram. This is only embedded on the page as an HTML link, so that when you open our website, no connection is yet established with the Instagram servers. If you click on the button, the website of the relevant social network will open in a new browser window. You can, for example, press the \"Like\" or \"Share\" button there.<br/> Instagram is a service of Facebook Ireland Ltd, 4 Grand Canal Square, Dublin 2, Ireland (\"Facebook Ireland\"). Information automatically collected by Facebook Ireland about your use of our online presence on Instagram is generally transmitted to a server of Facebook, Inc, 1601 Willow Road, Menlo Park, California 94025, USA and stored there.<br/>You can find Instagram's data protection statement here: https://help.instagram.com/519522125107875",
        pluginsFB: "Facebook",
        pluginsFB1: "On our website is the social button used by Facebook. This is only integrated into the page as an HTML link, so that when you open our website, no connection is yet established with the Instagram servers. If you click on the button, the website of the relevant social network will open in a new browser window. You can, for example, press the \"Like\" or \"Share\" button there. <br/> Facebook is a social network operated by Facebook Inc. at 1 Hacker Way, Menlo Park, California 94025, USA (\"Facebook\"). Information automatically collected by Facebook about your use of our online presence on Facebook is generally transmitted to a Facebook server and stored there. You can find Facebook's data protection statement here: https://www.facebook.com/about/privacy/",
        license:"License",
        license1: "Unless otherwise stated, B. K. Research & Analytics and/or its licensors own the intellectual property rights for all material on B. K. Research & Analytics. All intellectual property rights are reserved. You may access this from B. K. Research & Analytics for your own personal use subjected to restrictions set in these terms and conditions. <br/> You Must",
        license2: "Republish material from B. K. Research & Analytics",
        license3: "Sell, rent or sub-license material from B. K. Research & Analytics",
        license4: "Reproduce, duplicate or copy material from B. K. Research & Analytics",
        license5: "Redistribute content from B. K. Research & Analytics",
        license6: "This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms And Conditions Generator.<br/>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. B. K. Research & Analytics does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of B. K. Research & Analytics,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, B. K. Research & Analytics shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.<br/>B. K. Research & Analytics reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offerfensive or causes breach of these Terms and Conditions.<br/>You warrant and represent that:",
        license7: "You are entitled to post the Comments on our website and have all necessary licenses and consents to do so",
        license8: "The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party",
        license9: "The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy",
        license10: "The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.",
        license11: "You hereby grant B. K. Research & Analytics a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.",
        hyperLinking: "Hyperlinking to our Content",
        hyperLinking1: "The following organizations may link to our Website without prior written approval:",
        hyperLinking2: "Government agencies",
        hyperLinking3: "Search engines",
        hyperLinking4: "News organizations",
        hyperLinking5: "Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses",
        hyperLinking6: "System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.",
        hyperLinking7: "These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.<br/>We may consider and approve other link requests from the following types of organizations:",
        hyperLinking8: "commonly-known consumer and/or business information sources",
        hyperLinking9: "dot.com community sites",
        hyperLinking10: "associations or other groups representing charities",
        hyperLinking11: "online directory distributors",
        hyperLinking12: "internet portals",
        hyperLinking13: "accounting, law and consulting firms and",
        hyperLinking14: "educational institutions and trade associations.",
        hyperLinking15: "We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of B. K. Research & Analytics; and (d) the link is in the context of general resource information.<br/>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.<br/>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to B. K. Research & Analytics. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.<br/>Approved organizations may hyperlink to our Website as follows:",
        hyperLinking16: "By use of our corporate name; or",
        hyperLinking17: "By use of the uniform resource locator being linked to; or",
        hyperLinking18: "By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.",
        hyperLinking19: "No use of B. K. Research & Analytics's logo or other artwork will be allowed for linking absent a trademark license agreement.",
        iFrame: "iFrames",
        iFrame1: "Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.",
        contLiab: "Content Liability",
        contLiab1: "We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights."

    }
    }