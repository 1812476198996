import { useEffect, useState} from "react";
import { useParams, Link } from "react-router-dom";
import { teamList } from "../../../data/teamdetails/team";

const TeamdetailsPage = () =>{

   const { id } = useParams()
   const [loading, setLoading] = useState(true)
   const [actualTeam, setActualTeam] = useState(null)
   
   useEffect(()=>{
      for(let i=0; i<teamList.length; i++){
         if(teamList[i].id==id){
            setActualTeam(teamList[i])
            setLoading(false);
         }
      }
   },[])

    return (
      <>
         {!loading && (
            <>
               <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix" style={{background:"#16243E"}}>
                  <div className="breadcrumb__bg" ></div>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                              <h3 className="breadcrumb__title">Team</h3>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                           <div className="breadcrumb__list text-center text-sm-end">
                              <span><Link to="index.html">Home</Link></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span>Team Details</span>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="tp-team-details-breadcrumb-area pt-120 pb-90">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-5">
                           <div className="tp-team-details-thumb mb-85">
                              <img src="/assets/img/team/breadcrumb-img.jpg" alt=""/>
                           </div>
                        </div>
                        <div className="col-lg-7">
                           <div className="tp-team-details-wrapper mb-85">
                              <h3 className="tp-team-details-name">{actualTeam.name}</h3>
                              <span className="tp-team-details-description">{actualTeam.position}</span>
                              <p>{actualTeam.description} <br/> </p>
                              <div className="tp-team-details-information">
                                 <h4>
                                    <span>Phone :</span> <Link to={"tel:" + actualTeam.telephone}>{actualTeam.telephone}</Link>
                                 </h4>
                                 <h4>
                                    <span>Email :</span> <Link to={"mailto:" + actualTeam.email}>{actualTeam.email}</Link>
                                 </h4>
                                 <h4>
                                    <span>Website :</span> <Link to={actualTeam.website}>{actualTeam.website}</Link>
                                 </h4>
                                 <h4>
                                    <span>Address :</span> <Link to={"https://www.google.com/maps/search/" + actualTeam.address}>{actualTeam.address}</Link>
                                 </h4>
                              </div>
                              <div className="tp-team-details-social">
                                 <Link to={actualTeam.linkedin}><i className="fab fa-linkedin"></i></Link>
                                 <Link to={actualTeam.twitter}><i className="fab fa-twitter"></i></Link>
                                 {/* <Link to="#"><i className="fa-brands fa-google-plus-g"></i></Link>
                                 <Link to="#"><i className="fa-brands fa-instagram"></i></Link> */}
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-8 col-md-12">
                           <div className="tp-team-details-skills fix">
                              <h3 className="tp-team-details-title">Skills</h3>
                              <p>{actualTeam.skills}</p>
                              <div className="tp-team-details-skills-progress">
                                 {actualTeam.mainskill.map((mainsk)=>(
                                    <div className="tp-team-details-progress-item">
                                       <div className="tp-team-details-progress-title p-relative">
                                          <h5>{mainsk.skill} <span className={mainsk.clas} data-wow-duration=".8s" data-wow-delay=".3s">{mainsk.percent}</span></h5>
                                       </div>
                                       <div className="progress">
                                          <div className="progress-bar wow slideInLeft" data-wow-duration=".8s" data-wow-delay=".3s" role="progressbar" data-width={mainsk.percent} aria-valuenow={mainsk.perc} aria-valuemin="0" aria-valuemax="100"></div>
                                       </div>
                                    </div>
                                 ))}

                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                           <div className="tp-team-details-value">
                              <h3 className="tp-team-details-title">Others Skills</h3>
                              <ul>
                                 {actualTeam.otherskill.map((otherskill)=>(
                                 <li><i className="fa-solid fa-check"></i> {otherskill} </li>
                                 ))}
                              </ul>
                              <p>{actualTeam.social}</p>
                           </div>
                        </div>
                     </div>
                  </div>


               </section>
            </>
         )}

      </>
    )
}
export default TeamdetailsPage;
