import axios from 'axios';


const PAYMENT_API = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_URL,
    timeout: 1000000
})

const PARAM_API = axios.create({
    baseURL: process.env.REACT_APP_PARAM_URL,
    timeout: 100000
})

export {PARAM_API, PAYMENT_API}
