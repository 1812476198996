import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSwitcher from "../lang/langSwitcher";
const PageHeader = () =>{
    const {t} = useTranslation()
    function openSearch(){
        // var searchUI = window.$(".search-ui");
        // searchUI.toggleClass("open")
		window.$(".search-area").toggleClass("search-opened");
		window.$(".search-overlay").toggleClass("opened");
    }
    function openCanvas(){
		window.$(".offcanvas__area").toggleClass("offcanvas-opened");
		window.$(".body-overlay").toggleClass("opened");
    }
    return (
        <>

            <div className="back-to-top-wrapper">
                <button id="back_to_top" type="button" className="back-to-top-btn">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 6L6 1L1 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="search-area">
                <div className="search-inner p-relative">
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="search-wrapper">
                                <div className="search-close"  onClick={()=>{openSearch()}}>
                                <button className="search-close-btn">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1L1 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                </div>
                                <div className="search-content pt-35">
                                <h3 className="heading text-center mb-30">Hi! How can we help You?</h3>
                                <div className="d-flex justify-content-center px-5">
                                    <div className="search w-100 p-relative">
                                        <input type="text" className="search-input" placeholder="Search..."/>
                                        <button  className="search-icon">
                                        <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="search-overlay"></div>
            <div className="offcanvas__area">
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__close">
                    <button className="offcanvas__close-btn offcanvas-close-btn"  onClick={()=>{openCanvas()}}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 1L1 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    </div>
                    <div className="offcanvas__content">
                    <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                        <div className="offcanvas__logo logo">
                            <Link to="/">
                                <img
                                    alt="B.K. Research"
                                    src="/assets/img/logo/logo_small.png"
                                    className="app-logo"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="tp-main-menu-mobile fix d-xl-none mb-40"></div>

                    <div className="offcanvas__contact">
                        <h4 className="offcanvas__title">Contacts</h4>
                        <div className="offcanvas__contact-content d-flex">
                            <div className="offcanvas__contact-content-icon">
                                <i className="fa-sharp fa-solid fa-location-dot"></i>
                            </div>
                            <div className="offcanvas__contact-content-content">
                                <a href="https://www.google.com/maps/maps/@3.9207773,11.5266847,16.65z?entry=ttu">128, Rue 5972 Etoudi, Yaoundé 1er </a>
                            </div>
                        </div>
                        <div className="offcanvas__contact-content d-flex">
                            <div className="offcanvas__contact-content-icon">
                                <i className="fa-solid fa-envelope"></i>
                            </div>
                            <div className="offcanvas__contact-content-content">
                                <a href="mailto:contact@bkresearchandanalytics.com"> contact@bkresearchandanalytics.com  </a>
                            </div>
                        </div>
                        <div className="offcanvas__contact-content d-flex">
                            <div className="offcanvas__contact-content-icon">
                                <i className="fa-solid fa-phone"></i>
                            </div>
                            <div className="offcanvas__contact-content-content">
                                <a href="tel:696888241"> +237 696 888 241</a>
                            </div>
                        </div>
                    </div>
                    <div className="offcanvas__social">
                        <Link className="icon linkedin" href="https://www.linkedin.com/company/b-k-research-analytics/"><i className="fab fa-linkedin"></i></Link>
                        <Link className="icon twitter" href="#"><i className="fab fa-twitter"></i></Link>
                        {/* <Link className="icon youtube" href="#"><i className="fab fa-youtube"></i></Link> */}
                        <Link className="icon facebook" href="#"><i className="fab fa-facebook-f"></i></Link>
                    </div>
                    </div>
                </div>
            </div>
            <header className="tp-header-area-3 p-relative">
                <div className="tp-header-box-3">
                    <div className="tp-header-logo-3 d-none d-xl-block">
                    <Link to="/">
                        <img
                            alt="B.K. Research"
                            src="/assets/img/logo/logo_small.png"
                            className="app-logo"
                        />
                    </Link>
                    </div>

                    <div className="tp-header-box-main-3">
                    <div className="tp-header-top-3 d-none d-xl-block">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xxl-7 col-xl-9">
                                <div className="tp-header-top-info-3">
                                    <ul>
                                        <li>
                                            <a href="mailto:info@bkresearchandanalytics.com"><span><i className="fa-solid fa-envelope"></i></span>info@bkresearchandanalytics.com</a>
                                        </li>
                                        <li>
                                            <a href="https://www.google.com/maps/@3.9207773,11.5266847,16.65z?entry=ttu" target="_blank" rel="noreferrer"><span><i className="fa-sharp fa-solid fa-location-dot"></i></span>128, Rue 5972 Etoudi, Yaoundé 1er</a>
                                        </li>
                                        <li>
                                            <a href=""><span><i className="fa-solid fa-clock"></i></span>{t("textDay")} <b>{t("textTime")}</b></a>
                                        </li>
                                        <li>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-xxl-5 col-xl-3">
                                <div className="tp-header-top-right-3 d-flex justify-content-end align-items-center">
                                    <div className="tp-header-top-social-3">
                                        <Link to="https://www.linkedin.com/company/b-k-research-analytics/"><i className="fa-brands fa-linkedin"></i></Link>
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        {/* <Link to="#"><i className="fa-brands fa-instagram"></i></Link> */}
                                        <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                    </div>
                                    <div className="tp-header-top-support d-xxl-flex d-none">
                                        {/* <a href="mailto:support@bkresearchandanalytics.com">Help /</a> */}
                                        <a href="mailto:support@bkresearchandanalytics.com">Support /</a>
                                        <Link to="contact">Contact</Link>
                                        <LangSwitcher />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tp-header-wrapper-3">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xxl-6 col-xl-7 col-6">
                                <div className="tp-main-menu home-3 align-items-center justify-content-between d-flex">
                                    <div className="tp-main-menu-logo d-block d-xl-none">
                                        <Link to="/">
                                            <img
                                                alt="B.K. Research & Analytics"
                                                src="/assets/img/logo/logo_small.png"
                                                className="app-logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="d-none d-xl-flex">
                                        <nav className="tp-main-menu-content">
                                            <ul>
                                            <li><Link to="/">{t("textHome")}</Link></li>
                                            <li><Link to="about_us">{t("textAboutUs")}</Link></li>
                                            <li><Link to="service">Service</Link></li>
                                            <li><Link to="team">{t("textTeam")}</Link></li>
                                            {/* <li className="has-dropdown"><Link to="service.html">Services</Link>
                                                <ul className="submenu">
                                                    <li><Link to="service">Service</Link></li>
                                                    <li><Link to="service_details">Service Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-dropdown">
                                                <Link to="index.html">Page</Link>
                                                <ul className="submenu">
                                                    <li><Link to="project.html">Project</Link></li>
                                                    <li><Link to="project-details.html">Project Details</Link></li>
                                                    <li><Link to="team">Team</Link></li>
                                                    <li><Link to="teamdetails">Team Details</Link></li>
                                                    <li><Link to="faq">Faq</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-dropdown"><Link to="blog-classic.html">Blog</Link>
                                                <ul className="submenu">
                                                    <li><Link to="blog">Blog</Link></li>
                                                    <li><Link to="blog-details.html">Blog Details</Link></li>
                                                </ul>
                                            </li>*/}
                                            <li><Link to="blog">Blog</Link></li>
                                            <li><Link to="contact">Contact</Link></li>
                                            <li><Link to="faq">Faq</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                </div>
                                <div className="col-xxl-6 col-xl-5 col-6">
                                <div className="tp-header-main-right-3 d-flex align-items-center justify-content-xl-end">
                                    <div className="tp-header-contact d-xl-flex d-none align-items-center">
                                        <div className="tp-header-contact-inner-3 d-xxl-flex align-items-center d-none">
                                            <div className="tp-header-icon-3">
                                              <span><i className="fa-solid fa-phone"></i></span>
                                            </div>
                                            <div className="tp-header-contact-content">
                                              <p>{t("textCall")}</p>
                                              <span><a href="tel:696888241">(+237) 696 888 241</a></span>
                                            </div>
                                        </div>
                                        <div className="tp-header-contact-search-3 search-open-btn"  onClick={()=>{openSearch()}}>
                                            <span><i className="fa-solid fa-magnifying-glass"></i></span>
                                        </div>
                                        <div className="tp-header-btn-3">
                                            <Link className="tp-btn" to="contact">{t("textConsultation")}</Link>
                                        </div>
                                    </div>
                                    <div className="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn text-end"  onClick={()=>{openCanvas()}}>
                                        <button className="hamburger-btn">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </header>
            {/* <!-- header area end --> */}

            {/* <!-- sticky header start --> */}
            <header id="header-sticky" className="tp-header-main-sticky p-relative">
                    <div className="tp-header-space-2">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-6">
                                <div className="tp-header-logo-2 p-relative">
                                <Link to="/">
                                        <img
                                            alt="B.K. Research"
                                            src="/assets/img/logo/logo_small.png"
                                            className="app-logo"
                                        />
                                </Link>
                                </div>
                            </div>
                            <div className="col-lg-6 d-none d-xl-block">
                                <div className="tp-main-menu home-2 d-none d-xl-block">
                                <nav className="tp-main-menu-content">
                                    <ul>
                                        <li><Link to="/">{t("textHome")}</Link></li>
                                        <li><Link to="about_us">{t("textAboutUs")}</Link></li>
                                        <li><Link to="service">Service</Link></li>
                                        <li><Link to="team">{t("textTeam")}</Link></li>
                                        {/* <li className="has-dropdown"><Link to="service.html">Services</Link>
                                            <ul className="submenu">
                                            <li><Link to="service">Service</Link></li>
                                            <li><Link to="service_details">Service Details</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-dropdown">
                                            <Link to="index.html">Page</Link>
                                            <ul className="submenu">
                                            <li><Link to="project.html">Project</Link></li>
                                            <li><Link to="project-details.html">Project Details</Link></li>
                                            <li><Link to="team">Team</Link></li>
                                            <li><Link to="team-details.html">Team Details</Link></li>
                                            <li><Link to="faq">Faq</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-dropdown"><Link to="blog-classic.html">Blog</Link>
                                            <ul className="submenu">
                                            <li><Link to="blog">Blog</Link></li>
                                            <li><Link to="blog-details.html">Blog Details</Link></li>
                                            </ul>
                                        </li>*/}
                                        <li><Link to="blog">Blog</Link></li>
                                        <li><Link to="contact">Contact</Link></li>
                                        <li><Link to="faq">Faq</Link></li>
                                    </ul>
                                </nav>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6">
                                <div className="tp-header-main-right-2 d-flex align-items-center justify-content-xl-end">
                                <div className="tp-header-contact-2 d-flex align-items-center">
                                    <div className="tp-header-contact-search search-open-btn d-none d-xxl-block"  onClick={()=>{openSearch()}}>
                                        <span><i className="fa-solid fa-magnifying-glass"></i></span>
                                    </div>
                                    <div className="tp-header-contact-inner d-none d-xl-flex align-items-center">
                                        <div className="tp-header-contact-icon">
                                            <span><i className="fa-solid fa-phone"></i></span>
                                        </div>
                                        <div className="tp-header-contact-content">
                                            <p>{t("textCall")}</p>
                                            <span><a href="tel:696888241">(+237) 696 888 241</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn text-end" onClick={()=>{openCanvas()}}>
                                    <button className="hamburger-btn">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </header>
        </>
    );
}

export default PageHeader;
