import { Link } from "react-router-dom"
import { CHECK_MARK_SVG } from "../../components/utils/constants"
import Swal from "sweetalert2";

const showSuccessPage = (t, message, type) =>{
    Swal.fire({
        icon: type==='success'?"success":"error",
        text: message,
        showCancelButton: true,
        confirmButtonColor: '#1d3461',
        showCancelButton:false,
        timerProgressBar: true,
        width: '350px',
        heightAuto:"100px"
    }).then((result)=>{
        if(type==='success'){
            window.location='/';
        }
    })
    // return(
    //     <div className="tp-faq-tab-content tp-accordion wow fadeInRight col-lg-4 modal fade" style={{paddingTop:10}}>
    //         {/* <div className="col-4"> */}
    //             <div className="row">
    //                 <div className="col-2 centerHV" style={{paddingTop:10, height:"100"}}>
    //                     <div className="my-box mt-4"><img src={CHECK_MARK_SVG} className="my-img" alt="enterprise"/></div>
    //                 </div>
    //                 <div className="col-10 text-center">
    //                     <p className="mt-4">
    //                         {t("FORM.success")}
    //                     </p>
    //                 </div>
    //         {/* </div> */}
    //         </div>
    //     </div>
    // )
}

export { showSuccessPage};