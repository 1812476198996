import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import PageLayout from "../components/layouts/homeLayout";
import LandingPage from "../pages/landing";
import FaqPage from "../pages/faq";
import ContactPage from "../pages/contact";
import ServicePage from "../pages/service";
import ServicedetailsPage from "../pages/service/service_details";
import TeamPage from "../pages/team";
import TeamdetailsPage from "../pages/team/team_details";
import AboutusPage from "../pages/about_us";
import BlogPage from "../pages/blog";
import BlogdetailsPage from "../pages/blog/blog_details";
import TermPage from "../pages/terms";
import PrivacyPage from "../pages/privacy";
import TrainingPayment from "../pages/training/trainingPayment";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route element = {<PageLayout/>}>
                    <Route index path="/" element={<LandingPage/>}/>
                    <Route path="faq" element={<FaqPage/>}/>
                    <Route path="contact" element={<ContactPage/>}/>
                    <Route path="service" element={<ServicePage/>}/>
                    <Route path="service_details/:id" element={<ServicedetailsPage/>}/>
                    <Route path="team" element={<TeamPage/>}/>
                    <Route path="team_details/:id" element={<TeamdetailsPage/>}/>
                    <Route path="about_us" element={<AboutusPage/>}/>
                    <Route path="blog" element={<BlogPage/>}/>
                    <Route path="blog_details/:id" element={<BlogdetailsPage/>}/>
                    <Route path="term" element={<TermPage/>}/>
                    <Route path="privacy-policy" element={<PrivacyPage/>}/>
                </Route>
                <Route path="training" element={<TrainingPayment/>}/>
                <Route path="training/:id" element={<TrainingPayment/>}/>
            </Routes>
        </Router>
    )
}

export default AppRoutes;
