import {teamList} from "../../data/teamdetails/team";
import {Link, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { useEffect } from "react";
const AboutusPage = () =>{
  const navigate = useNavigate();
  useEffect(()=>{
      document.title = "B.K. Research & Analytics | About Us"
   },[]);
  const {t} = useTranslation();
    return (
        <>

         <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"  style={{background:"#16243E"}}>
                  <div className="breadcrumb__bg"  data-background="/assets/img/breadcrumb/bg.png"></div>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                              <h3 className="breadcrumb__title">{t("textAboutUs")}</h3>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div className="breadcrumb__content">
                           <div className="breadcrumb__list text-center text-sm-end">
                              <span><a href="/">{t("textHome")}</a></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span>{t("textAboutUs")}</span>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
         </section>

         <section className="tp-about-area p-relative pt-130 pb-210">
             <div className="tp-about-shape">
                <img src="/assets/img/about/shape-3.png" alt=""/>
             </div>
             <div className="container">
              <div className="row">
                 <div className="col-lg-6">
                    <div className="tp-about-thumb-wrapper p-relative wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s">
                       <div className="main">
                          <img src="/assets/img/about/about_1.jpg" alt=""/>
                       </div>
                       <img className="shape-1" src="/assets/img/about/about_2.jpg" alt=""/>
                       <img className="shape-2" src="/assets/img/about/shape-1.png" alt=""/>
                       <img className="shape-3" src="/assets/img/about/shape-2.png" alt=""/>
                       <img className="shape-4" src="/assets/img/about/shape-4-bis.png" alt=""/>
                    </div>
                 </div>
                 <div className="col-lg-6">
                    <div className="tp-about-wrapper">
                       <div className="tp-about-title-wrapper">
                          <span className="tp-section-title-pre">{t("textAbout")}</span>
                          <h3 className="tp-section-title">Move Forward With Data</h3>
                       </div>
                       <p align="justify">{t("textAbdescp")}</p>
                       <div className="tp-about-wrapper-list">
                          <ul>
                             <li><span><i className="fa-regular fa-circle"></i></span> {t("textAbserv1")} </li>
                             <li><span><i className="fa-regular fa-circle"></i></span> {t("textAbserv2")}</li>
                             <li><span><i className="fa-regular fa-circle"></i></span> {t("textAbserv3")} </li>
                             <li><span><i className="fa-regular fa-circle"></i></span> {t("textAbserv4")}</li>
                          </ul>
                       </div>
                       <div className="tp-about-btn">
                          <a className="tp-btn" to="/about_us">{t("textAboutUs")} <span><i className="fa-regular fa-plus"></i></span></a>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
         </section>

         <section className="tp-faq-area p-relative pt-120 pb-120" style={{background:'url("/assets/img/faq/bg-shape.png")'}}>
              <div className="tp-faq-bg"></div>
              <div className="container">
                 <div className="row">
                    <div className="col-lg-5">
                       <div className="tp-faq-wrapper">
                          <div className="tp-faq-title-wrapper">
                             <span className="tp-section-title-pre">{t("textAsktitle0")}</span>
                             <h3 className="tp-section-title"><span> {t("textAsktitle1")} </span><br/>  <span> {t("textAsktitle2")} </span>  </h3>
                             <p align="justify">{t("textAskdesp1")}<a href="mailto:contact@bkresearchandanalytics.com" style={{ color:'#C2F970'}}>{t("textAskad")}</a>{t("textAskdesp2")}</p>
                          </div>
                          <div className="tp-faq-counter-wrapper d-flex">
                             <div className="tp-faq-counter d-flex align-items-center mr-20 mb-30">
                                <div className="tp-faq-counter-icon">
                                   <span>
                                      <img src="/assets/img/faq/icon-1.svg" alt=""/>
                                   </span>
                                </div>
                                <div className="tp-faq-counter-content">
                                <h4 className="tp-faq-counter-title"><CountUp className="purecounter" start={0} end={2} delay={5} data-purecounter-end="10"/>+</h4>
                                   <p>Experiences</p>
                                </div>
                             </div>
                             <div className="tp-faq-counter d-flex align-items-center mb-30">
                                <div className="tp-faq-counter-icon">
                                   <span>
                                      <img src="/assets/img/faq/icon-2.svg" alt=""/>
                                   </span>
                                </div>
                                <div className="tp-faq-counter-content">
                                  <h4 className="tp-faq-counter-title"><CountUp className="purecounter" start={0} end={2} delay={5} data-purecounter-end="2"/>+</h4>
                                  <p>{t("textAsksat")}</p>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div className="col-lg-7">
                       <div className="tp-faq-tab-content tp-accordion wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s">
                          <div className="accordion" id="general_accordion">
                             <div className="accordion-item tp-faq-active">
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    {t("textAskfaq.que1")}
                                  </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#general_accordion">
                                  <div className="accordion-body">
                                    <p align="justify">{t("textAskfaq.ans1")}</p>
                                  </div>
                                </div>
                              </div>
                             <div className="accordion-item">
                               <h2 className="accordion-header" id="headingTwo">
                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {t("textAskfaq.que2")}
                                 </button>
                               </h2>
                               <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#general_accordion">
                                 <div className="accordion-body">
                                   <p align="justify">{t("textAskfaq.ans2")}</p>
                                 </div>
                               </div>
                             </div>
                             <div className="accordion-item">
                               <h2 className="accordion-header" id="headingThree">
                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    {t("textAskfaq.que3")}
                                 </button>
                               </h2>
                               <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#general_accordion">
                                 <div className="accordion-body">
                                   <p align="justify">{t("textAskfaq.ans3")}</p>
                                 </div>
                               </div>
                             </div>
                             <div className="accordion-item">
                               <h2 className="accordion-header" id="headingFour">
                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    {t("textAskfaq.que4")}
                                 </button>
                               </h2>
                               <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#general_accordion">
                                 <div className="accordion-body">
                                   <p align="justify">{t("textAskfaq.ans4")}</p>
                                 </div>
                               </div>
                             </div>
                           </div>
                       </div>
                    </div>
                 </div>
              </div>
         </section>

         <section className="tp-process-arae pt-120 pb-120" style={{background:"#F6F6F9"}}>
            <div className="container">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="tp-process-wrapper">
                        <div className="tp-process-title mb-40">
                           <span className="tp-section-title-pre">{t("textProcess")}</span>
                           <h3 className="tp-section-title">{t("textProtitle")}</h3>
                        </div>
                        <div className="tp-process-item d-flex align-items-center">
                           <div className="tp-process-item-icon">
                              <span>
                                 <img src="/assets/img/process/icon-1.svg" alt=""/>
                              </span>
                           </div>
                           <div className="tp-process-item-content">
                              <h4 className="tp-process-item-title">{t("textProitem11")}</h4>
                              <p align="justify">{t("textProitem12")}</p>
                           </div>
                        </div>
                        <div className="tp-process-item d-flex align-items-center">
                           <div className="tp-process-item-icon">
                              <span>
                                 <img src="/assets/img/process/icon-2.svg" alt=""/>
                              </span>
                           </div>
                           <div className="tp-process-item-content">
                              <h4 className="tp-process-item-title">{t("textProitem21")}  </h4>
                              <p align="justify">{t("textProitem22")} </p>
                           </div>
                        </div>
                        <div className="tp-process-item d-flex align-items-center">
                           <div className="tp-process-item-icon">
                              <span>
                                 <img src="/assets/img/process/icon-3.svg" alt=""/>
                              </span>
                           </div>
                           <div className="tp-process-item-content">
                              <h4 className="tp-process-item-title">{t("textProitem31")}</h4>
                              <p align="justify">{t("textProitem32")}</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="tp-process-thumb-wrapper p-relative wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s">
                        <div className="tp-process-thumb p-relative">
                           <div className="main">
                              <img src="/assets/img/process/process_1.jpg" alt=""/>
                           </div>
                           <img className="shape-1" src="/assets/img/process/process_2.jpg" alt=""/>
                        </div>
                        <div className="tp-process-counter d-flex align-items-center">
                           <div className="tp-process-counter-icon">
                              <span>
                                 <i className="flaticon-030-machine-repair"></i>
                              </span>
                           </div>
                           <div className="tp-process-content">
                              <h4 className="tp-process-counter-title">
                              <CountUp className="purecounter" start={0} end={2} delay={2} data-purecounter-end="4"/>
                              {/* <span className="purecounter" data-purecounter-duration="2" data-purecounter-end="4"></span> */}
                               +
                              </h4>
                              <p >{t("textPropro")}</p>
                           </div>
                        </div>
                        <div className="tp-process-trusted text-center">
                           <p>Trusted By</p>
                           <p><span>2</span></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="tp-team-area pt-120 pb-120 tp-team-item-margin" >
           <div className="container">
              <div className="row">
                 <div className="col-lg-12">
                    <div className="tp-team-title-wrapper text-center mb-30">
                       <span className="tp-section-title-pre">{t("textOurTeam.title")}</span>
                       <h3 className="tp-section-title"><span> {t("textOurTeam.slogpart1")}</span> <br/> <span> {t("textOurTeam.slogpart2")}</span> </h3>
                    </div>
                 </div>
              </div>
              <div className="row">
              {teamList.map((team)=>(
                 <div className="col-lg-3 col-md-6">
                    <div className="tp-team-item p-relative wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                       <div className="tp-team-item-thumb" onClick={()=>{navigate("/team_details", {state:{service:team, teamList:teamList}})}}>
                          {/* <a href="team-details.html"><img src="/assets/img/team/img-1.jpg" alt=""></a> */}
                          <Link to={{pathname:"/team_details", state:{service:team}}}><img src={team.image} alt=""/></Link>
                       </div>
                       <div className="tp-team-social">
                          <a className="icon-1" href={team.linkedin}><i className="fa-brands fa-linkedin"></i></a>
                          <a className="icon-2" href={team.twitter}><i className="fa-brands fa-twitter"></i></a>
                          {/* <a className="icon-3" href="#"><i className="fa-brands fa-instagram"></i></a> */}
                          {/* <a className="icon-4" href="#"><i className="fa-brands fa-pinterest"></i></a> */}
                       </div>
                       <div className="tp-team-info text-center">
                          {/*<h4 className="tp-team-info-title"><a href="team-details.html">Albert Flores</a></h4> */}
                          <h4 className="tp-team-info-title" onClick={()=>{navigate("/team_details", {state:{service:team, teamList:teamList}})}}><Link to={{pathname:"/team_details", state:{service:team}}}>{team.name} </Link></h4>
                          <p>{team.position}</p>
                       </div>
                    </div>
                 </div>

                 ))}

                 {/*
                   <div className="col-lg-3 col-md-6">
                      <div className="tp-team-item p-relative wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                         <div className="tp-team-item-thumb">
                            <a href="team-details.html"><img src="/assets/img/team/img-1.jpg" alt=""></a>
                         </div>
                         <div className="tp-team-social">
                            <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                            <a className="icon-2" href="#"><i className="fa-brands fa-twitter"></i></a>
                            <a className="icon-3" href="#"><i className="fa-brands fa-instagram"></i></a>
                            <a className="icon-4" href="#"><i className="fa-brands fa-pinterest"></i></a>
                         </div>
                         <div className="tp-team-info text-center">
                            <h4 className="tp-team-info-title"><a href="team-details.html">Albert Flores</a></h4>
                            <p>Designer</p>
                         </div>
                      </div>
                   </div>
                   <div className="col-lg-3 col-md-6">
                      <div className="tp-team-item p-relative wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                         <div className="tp-team-item-thumb">
                            <a href="team-details.html"><img src="/assets/img/team/img-2.jpg" alt=""></a>
                         </div>
                         <div className="tp-team-social">
                            <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                            <a className="icon-2" href="#"><i className="fa-brands fa-twitter"></i></a>
                            <a className="icon-3" href="#"><i className="fa-brands fa-instagram"></i></a>
                            <a className="icon-4" href="#"><i className="fa-brands fa-pinterest"></i></a>
                         </div>
                         <div className="tp-team-info text-center">
                            <h4 className="tp-team-info-title"><a href="team-details.html">Kathryn Murphy</a></h4>
                            <p>Designer</p>
                         </div>
                      </div>
                   </div>
                   <div className="col-lg-3 col-md-6">
                      <div className="tp-team-item p-relative wow fadeInUp" data-wow-duration="1s" data-wow-delay=".7s">
                         <div className="tp-team-item-thumb">
                            <a href="team-details.html"><img src="/assets/img/team/img-3.jpg" alt=""></a>
                         </div>
                         <div className="tp-team-social">
                            <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                            <a className="icon-2" href="#"><i className="fa-brands fa-twitter"></i></a>
                            <a className="icon-3" href="#"><i className="fa-brands fa-instagram"></i></a>
                            <a className="icon-4" href="#"><i className="fa-brands fa-pinterest"></i></a>
                         </div>
                         <div className="tp-team-info text-center">
                            <h4 className="tp-team-info-title"><a href="team-details.html">Marvin McKinney</a></h4>
                            <p>Designer</p>
                         </div>
                      </div>
                   </div>
                   <div className="col-lg-3 col-md-6">
                      <div className="tp-team-item p-relative wow fadeInUp" data-wow-duration="1s" data-wow-delay=".9s">
                         <div className="tp-team-item-thumb">
                            <a href="team-details.html"><img src="/assets/img/team/img-4.jpg" alt=""></a>
                         </div>
                         <div className="tp-team-social">
                            <a className="icon-1" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                            <a className="icon-2" href="#"><i className="fa-brands fa-twitter"></i></a>
                            <a className="icon-3" href="#"><i className="fa-brands fa-instagram"></i></a>
                            <a className="icon-4" href="#"><i className="fa-brands fa-pinterest"></i></a>
                         </div>
                         <div className="tp-team-info text-center">
                            <h4 className="tp-team-info-title"><a href="team-details.html">Leslie Alexander</a></h4>
                            <p>Designer</p>
                         </div>
                      </div>
                   </div>
                   */}
              </div>
           </div>
         </section>

        </>
    )
}
export default AboutusPage;
