
import {useEffect, useState} from "react";
import { Link,  useParams } from "react-router-dom";
import { serviceList } from "../../../data/servicedetails/service";
import { useTranslation } from "react-i18next";

const ServicedetailsPage = () =>{

   // let {service, serviceList} = state?? {};
   const {t} = useTranslation()
   const { id } = useParams()
   const [actualService, setActualService] = useState(null)
   const [loading, setLoading] = useState(true)
   /* const switchService=(serv)=>{
      setActualService(serv)
      console.log("Changin service")
      console.log(actualService?.title)
   } */
   useEffect(()=>{
      for(let i=0; i<serviceList.length; i++){
         if(serviceList[i].id==id){
            setLoading(false)
            // console.log(serviceList[i])
            setActualService(serviceList[i])
         }
      }
      // if(!service || !serviceList){
      //    navigate("/service");
      // }
   },[])
   var subSection1 = t(actualService?.subSection1, { returnObjects: true })
   var characteristics = t(actualService?.characteristics, { returnObjects: true })
    return (
      
        <>
         {loading?(<h3>Loading...</h3>):( 
            <>
               <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix" style={{background:"#16243E"}}>
                  <div className="breadcrumb__bg" ></div>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                              <h3 className="breadcrumb__title">Service Details</h3>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                           <div className="breadcrumb__list text-center text-sm-end">
                              <span><Link to="/">Home</Link></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span><Link to="/service">Service</Link></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span>Service Details</span>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

               <section className="tp-service-details-area pt-120 pb-120">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-8">
                           <div className="tp-service-details-wrapper">
                              <div className="tp-service-details-thumb">
                                 <img src={actualService?.image1} alt=""/>
                              </div>
                              <h3 className="tp-service-details-title">{t(`${actualService?.subTitle1}`)}</h3>
                              <p align = "justify">{t(`${actualService?.description1}`)}</p>
                              <p align = "justify">{t(`${actualService?.description2}`)}</p>
                              <div className="tp-service-details-box d-flex mb-60">
                                 
                                 {subSection1.map((subpart, index)=>(
                                    <div className="tp-service-details-item d-flex mr-30" key={index}>
                                       <div className="tp-service-details-icon">
                                          <img src={subpart.image} alt=""/>
                                       </div>
                                       <div className="tp-service-details-content">
                                          <h3 className="tp-service-details-subtitle">{subpart.title}</h3>
                                          <p align = "justify">{subpart.description}</p>
                                       </div>
                                    </div>
                                 ))}
                                 {/* <div className="tp-service-details-item d-flex mr-30">
                                    <div className="tp-service-details-icon">
                                       <img src="/assets/img/service/service-details/icon-1.svg" alt=""/>
                                    </div>
                                    <div className="tp-service-details-content">
                                       <h3 className="tp-service-details-subtitle">Analysis Data</h3>
                                       <p>CR²-SME integrate data from two sources, that are financial statements (hard data) and market information (soft-data).</p>
                                    </div>
                                 </div>

                                 <div className="tp-service-details-item d-flex">
                                    <div className="tp-service-details-icon">
                                       <img src="/assets/img/service/service-details/icon-2.svg" alt=""/>
                                    </div>
                                    <div className="tp-service-details-content">
                                       <h3 className="tp-service-details-subtitle">Automation</h3>
                                       <p>CR²-SME allow to automate complex procedures such as credit risk assessment, loan pricing, and application decision-making.</p>
                                    </div>
                                 </div> */}
                              </div>

                              <div className="tp-service-details-box-2 d-flex mb-40">
                                 <div className="tp-service-details-box-content mr-30">
                                    <h3 className="tp-service-details-subtitle-2">{t(`${actualService?.benefit}`)}</h3>
                                    <p align = "justify">{t(`${actualService?.benefit1}`)}</p>
                                    <p align = "justify">{t(`${actualService?.benefit2}`)}</p>
                                    {/* <p>With CR²-SME, Bank can improve the efficiency and accuracy of credit risk assessment, reduce the cost and time of credit processing, and enhance the portfolio management and monitoring.</p>
                                    <p>This allow more credit opportunities for SMEs, with lower interest rates, faster and simpler loan applications.</p> */}
                                 </div>
                                 <div className="tp-service-details-box-thumb">
                                    <img src={actualService?.image2} alt=""/>
                                 </div>
                              </div>

                              <div className="tp-service-details-quote">
                                 <span align = "justify">{t(`${actualService?.other}`)}</span>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4">
                           <div className="tp-service-widget">
                              <div className="tp-service-widget-item mb-40">
                                 <div className="tp-service-widget-tab">
                                    <h3 className="tp-service-widget-title">{t("textOurServices")}</h3>
                                    <ul>
                                       {serviceList?.map((service_)=>(
                                          <li className={actualService?.title.includes(`${service_.title}`) ? 'active my-title' : 'my-title'} 
                                          //  onClick={()=>{switchService(service_)}} style={{hover:"pointer"}}
                                         key={service_.id} ><Link to={`/service_details/${service_.id}`} onClick={(e)=>{setActualService(service_)}}>{t(`${service_.title}`)}</Link></li>
                                       ))}
                                    </ul>
                                 </div>
                              </div>

                              <div className="tp-service-widget-contact mb-40" data-background="/assets/img/service/service-details/img-3.jpg">
                                 <div className="tp-service-widget-contact-content text-center">
                                    <div className="tp-service-widget-contact-icon">
                                       <span><i className="fa-solid fa-phone"></i></span>
                                    </div>
                                    <p>Requesting A Call:</p>
                                    <a href="tel:696888241">+237 696 888 241</a>
                                 </div>
                              </div>

                              <div className="tp-service-widget-download mb-40">
                                 <a href="#"><span>Download Brochure <img src="/assets/img/service/service-details/icon-3.svg" alt=""/></span></a>
                              </div>

                              <div className="tp-service-widget-list">
                                 <div className="tp-service-widget-list-content">
                                    <ul>
                                       {characteristics.map((characteristic, index)=>(
                                       <li key={index}><i className="fa-light fa-circle-check"></i> {characteristic} </li>
                                       ))}
                                       {/* <li><i className="fa-light fa-circle-check"></i> Soft data included </li>
                                       <li><i className="fa-light fa-circle-check"></i> Financial Statements uploaded </li>
                                       <li><i className="fa-light fa-circle-check"></i> More than 150 ratios calculated </li> */}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               {(actualService?.subSection2 && actualService?.subSection2?.length!==0)?
                  (<section className="tp-team-breadcrumb-area pb-70" style={{background:"#F6F6F9"}}>
                     <div className="row pt-120 tp-service-details-subtitle-2">
                        <h1 className="text-center">Service Pricing</h1>
                     </div>
                     <div className="container">
                        <div className="row">
                        {actualService?.subSection2?.map((pack, index)=>(
                        <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                           <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                              <div className="tp-team-item-thumb" >
                                 {/*//<a href="team-details.html"><img src={pack.image} alt=""/></a> */}
                                 <img src={pack.image} alt=""/>
                              </div>

                              <div className="tp-team-info text-center">
                                 {/*// <h4 className="tp-service-item-title"><a href="service-details.html">Financial Forecasting </a></h4>
                                 // <h4 className="tp-service-item-title" onClick={()=>{navigate("/service_details", {state:{service:service, serviceList:serviceList}})}}><Link to={{pathname:"/service_details", state:{service:service}}} >{service.title}</Link></h4>
                                 // <h4 className="tp-team-info-title"><Link to="team_details">Bomyr Kamguia </Link></h4> */}
                                 <h4 className="tp-team-info-title" >{pack.title}</h4>
                                 <div className="tp-service-widget-list-content">
                                 <ul>
                                    <li><p style={{textAlign: "left"}}><strong>Input      : </strong>{pack.input}</p></li>
                                    <li><p style={{textAlign: "left"}}><strong>Output     : </strong>{pack.output}</p></li>
                                    <li><p style={{textAlign: "left"}}><strong>Onboarding : </strong>{pack.Onboarding}</p></li>
                                    <li><p style={{textAlign: "left"}}><strong>Price per transaction : </strong>{pack.price_transaction}</p></li>
                                 </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        ))}
                        </div>
                     </div>
                  </section>):(<div></div>)}
            </>
         )}
        </>
    )
}
export default ServicedetailsPage;
