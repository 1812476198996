import {Outlet, useLocation} from "react-router-dom";

import PageHeader from "../header";
import PageFooter from "../footer";
import { useLayoutEffect } from "react";
const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
const PageLayout = () =>{
    return (
        <>
            <div id="loading">
                <div id="loading-center">
                    <div className="preloader"></div>
                </div>  
            </div>
            <PageHeader />
            <div className="body-overlay"></div>
            <main  style={{minHeight:"80vh"}}>
                <Wrapper>
                    <Outlet/>
                </Wrapper>
            </main>
            <PageFooter/>
        </>
    );
}

export default PageLayout;