import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import i18n from "../../translations/translations"
import { changeLanguage } from "../../store/slices/language";




const LangSwitcher = () => {

    const { t } = useTranslation();
    const { language } = useSelector((state) => state.locale);
    const dispatch = useDispatch();

    const handleChangeLanguage = (lng) => {
        window.localStorage.setItem("lang",lng)
        dispatch(changeLanguage(lng === "fr" ? lng : "en"))

        i18n.changeLanguage(lng === "fr" ? lng : "en");
    }

    useEffect(() => {
            const lng = window.localStorage.getItem("lang");
            dispatch(changeLanguage(lng === "fr" ? lng : "en"));
            i18n.changeLanguage(lng === "fr" ? lng : "en");

    }, [dispatch]);

    return (
        <div className="mr-0 my-auto">
            {/* <select onChange={(e)=>{handleChangeLanguage(e.target.value)}} >
                <option value={"fr"}><span className="fi fi-fr"></span></option>
                <option value={"eng"}><span className="fi fi-gb"></span></option>
            </select> */}
            <div className="dropdown text-primary">
                <button className="btn dropdown-toggle bg-transparent text-capitalize" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {language==="fr"?
                    (<span className="fi fi-fr"></span>):(<span className="fi fi-gb"></span>)
                    }
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage("fr")}><span className="fi fi-fr"></span> {t("textLngFrench")}</Link>
                    <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage("eng")}><span className="fi fi-gb"></span> {t("textLngEnglish")}</Link>
                </div>
            </div>
        </div>
    )
}

export default LangSwitcher;