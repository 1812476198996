import { useEffect } from "react";
import {teamList} from "../../data/teamdetails/team";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
const TeamPage = () =>{
   const {t} = useTranslation();
   useEffect(()=>{
      document.title = "B.K. Research & Analytics | Teams"
   },[]);
    return (
        <>

         <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix" style={{background:"#16243E"}}>
            <div className="breadcrumb__bg" ></div>
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-sm-6">
                     <div className="breadcrumb__content">
                        <h3 className="breadcrumb__title">Team</h3>
                     </div>
                  </div>
                  <div className="col-sm-6">
                     <div className="breadcrumb__content">
                     <div className="breadcrumb__list text-center text-sm-end">
                        <span><Link to="/">{t("textHome")}</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                        <span><Link to="/team">Team</Link></span>
                     </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="tp-team-breadcrumb-area pt-120 pb-70">
            <div className="container">
               <div className="row">
               {teamList.map((team)=>(
                 <div className="col-xl-3 col-lg-4 col-md-6" key={team.id}>
                    <div className="tp-team-item p-relative mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                       <div className="tp-team-item-thumb" 
                     //   onClick={()=>{navigate("/team_details", {state:{service:team, teamList:teamList}})}}
                       >
                          {/*//<a href="team-details.html"><img src={team.image} alt=""/></a> */}
                          <Link to={`/team_details/${team.id}`}><img src={team.image} alt=""/></Link>
                       </div>
                       <div className="tp-team-social">
                          <a className="icon-1" href={team.linkedin}><i className="fa-brands fa-linkedin"></i></a>
                          <a className="icon-2" href={team.twitter}><i className="fa-brands fa-twitter"></i></a>
                          {/* <a className="icon-3" href="#"><i className="fa-brands fa-instagram"></i></a> */}
                          {/* <a className="icon-4" href="#"><i className="fa-brands fa-pinterest"></i></a> */}
                       </div>
                       <div className="tp-team-info text-center">
                          <h4 className="tp-team-info-title" 
                          ><Link to={`/team_details/${team.id}`}>{team.name} </Link></h4>
                          <p>{team.position}</p>
                       </div>
                    </div>
                 </div>
               ))}
               </div>
            </div>
         </section>

        </>
    )
}
export default TeamPage;
