import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
const FaqPage = () =>{
  const {t} = useTranslation()
  useEffect(()=>{
    document.title = "B.K. Research & Analytics | F.A.Q"
 },[]);
    return (
        <>

         <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix" style={{background:"#16243E"}}>
            <div className="breadcrumb__bg" ></div>
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-sm-6">
                     <div className="breadcrumb__content">
                        <h3 className="breadcrumb__title">FAQ</h3>
                     </div>
                  </div>
                  <div className="col-sm-6">
                     <div className="breadcrumb__content">
                     <div className="breadcrumb__list text-center text-sm-end">
                        {/*<span><Link to="/">Home</Link></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                        <span><Link to="/faq">FAQ</Link></span>*/}
                        <span><a href="index.html">{t("textHome")}</a></span>
                        <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                        <span>FAQ</span>
                     </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="tp-faq-breadcrumb-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  {/* Business Consulting */}
                  <div className="col-lg-6">
                     <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                        <div className="accordion" id="general_accordion">
                           <div className="accordion-item tp-faq-active">
                              <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {t("textFaq1.que1")}
                                </button>
                              </h2>
                              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#general_accordion">
                                <div className="accordion-body">
                                  <p align = "justify">{t("textFaq1.ans1")}</p>
                                </div>
                              </div>
                            </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingTwo">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                               {t("textFaq1.que2")}
                               </button>
                             </h2>
                             <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#general_accordion">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq1.ans2")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingThree">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                               {t("textFaq1.que3")}
                               </button>
                             </h2>
                             <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#general_accordion">
                               <div className="accordion-body">
                                 <p align="justify"> {t("textFaq1.ans3")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingFour">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                               {t("textFaq1.que4")}
                               </button>
                             </h2>
                             <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#general_accordion">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq1.ans4")}</p>
                               </div>
                             </div>
                           </div>
                         </div>
                     </div>
                  </div>
                  {/* Credit Risk Rating */}
                  <div className="col-lg-6">
                     <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                        <div className="accordion" id="general_accordion-2">
                           <div className="accordion-item tp-faq-active">
                              <h2 className="accordion-header" id="headingfive">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="true" aria-controls="fiveOne">
                                {t("textFaq2.que1")}
                                </button>
                              </h2>
                              <div id="collapsefive" className="accordion-collapse collapse show" aria-labelledby="headingfive" data-bs-parent="#general_accordion-2">
                                <div className="accordion-body">
                                  <p align = "justify"> {t("textFaq2.ans1")}</p>
                                </div>
                              </div>
                            </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingsix">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                               {t("textFaq2.que2")}
                               </button>
                             </h2>
                             <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#general_accordion-2">
                               <div className="accordion-body">
                                 <p align="justify"> {t("textFaq2.ans2")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingseven">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                               {t("textFaq2.que3")}
                               </button>
                             </h2>
                             <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#general_accordion-2">
                               <div className="accordion-body">
                                 <p align="justify"> {t("textFaq2.ans3")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingeight">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                               {t("textFaq2.que4")}
                               </button>
                             </h2>
                             <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#general_accordion-2">
                               <div className="accordion-body">
                                 <p>{t("textFaq2.ans4")} <Link to={`/service_details/${1}`} style={{ color: '#00F' }}>{t("textFaq2.ans5")}</Link>{t("textFaq2.ans6")} </p>
                               </div>
                             </div>
                           </div>
                         </div>
                     </div>
                  </div>
                  {/*Market Basket Analytics */}
                  <div className="col-lg-6">
                     <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                        <div className="accordion" id="general_accordion-3">
                           <div className="accordion-item tp-faq-active">
                              <h2 className="accordion-header" id="headingnine">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="true" aria-controls="nineOne">
                                {t("textFaq3.que1")}
                                </button>
                              </h2>
                              <div id="collapsenine" className="accordion-collapse collapse show" aria-labelledby="headingnine" data-bs-parent="#general_accordion-3">
                                <div className="accordion-body">
                                  <p align="justify">{t("textFaq3.ans1")}</p>
                                </div>
                              </div>
                            </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingten">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                               {t("textFaq3.que2")}
                               </button>
                             </h2>
                             <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#general_accordion-3">
                               <div className="accordion-body">
                                 <p align="justify" > {t("textFaq3.ans2")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingeleven">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
                               {t("textFaq3.que3")}
                               </button>
                             </h2>
                             <div id="collapseeleven" className="accordion-collapse collapse" aria-labelledby="headingeleven" data-bs-parent="#general_accordion-3">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq3.ans3")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingtwelve">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwelve" aria-expanded="false" aria-controls="collapsetwelve">
                               {t("textFaq3.que4")}
                               </button>
                             </h2>
                             <div id="collapsetwelve" className="accordion-collapse collapse" aria-labelledby="headingtwelve" data-bs-parent="#general_accordion-3">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq3.ans4")} <a href="mailto:contact@bkresearchandanalytics">{t("textFaq3.ans5")} </a> </p>
                               </div>
                             </div>
                           </div>
                         </div>
                     </div>
                  </div>
                  {/* Financial Forecasting */}
                  <div className="col-lg-6">
                     <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                        <div className="accordion" id="general_accordion-4">
                           <div className="accordion-item tp-faq-active">
                              <h2 className="accordion-header" id="headingthirtheen">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethirtheen" aria-expanded="true" aria-controls="nineOne">
                                {t("textFaq4.que1")}
                                </button>
                              </h2>
                              <div id="collapsethirtheen" className="accordion-collapse collapse show" aria-labelledby="headingthirtheen" data-bs-parent="#general_accordion-4">
                                <div className="accordion-body">
                                  <p align="justify">{t("textFaq4.ans1")}</p>
                                </div>
                              </div>
                            </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingfourtheen">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefourtheen" aria-expanded="false" aria-controls="collapsefourtheen">
                               {t("textFaq4.que2")}
                               </button>
                             </h2>
                             <div id="collapsefourtheen" className="accordion-collapse collapse" aria-labelledby="headingfourtheen" data-bs-parent="#general_accordion-4">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq4.ans2")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingfiftheen">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefiftheen" aria-expanded="false" aria-controls="collapsefiftheen">
                               {t("textFaq4.que3")}
                               </button>
                             </h2>
                             <div id="collapsefiftheen" className="accordion-collapse collapse" aria-labelledby="headingfiftheen" data-bs-parent="#general_accordion-4">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq4.ans3")}</p>
                               </div>
                             </div>
                           </div>
                           <div className="accordion-item">
                             <h2 className="accordion-header" id="headingsixtheen">
                               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesixtheen" aria-expanded="false" aria-controls="collapsesixtheen">
                               {t("textFaq4.que4")}
                               </button>
                             </h2>
                             <div id="collapsesixtheen" className="accordion-collapse collapse" aria-labelledby="headingsixtheen" data-bs-parent="#general_accordion-4">
                               <div className="accordion-body">
                                 <p align="justify">{t("textFaq4.ans4")}<Link to={`/service_details/${2}`} style={{ color: '#00F' }}>{t("textFaq4.ans5")}</Link>{t("textFaq4.ans6")}</p>
                               </div>
                             </div>
                           </div>
                         </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

        </>
    )
}
export default FaqPage;
