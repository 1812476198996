import {blogList} from "../../data/blogdetails/blog";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
const BlogPage = () =>{
  
  const {t} = useTranslation()
  useEffect(()=>{
   document.title = "B.K. Research & Analytics | Blog"
},[]);
    return (
        <>

         <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"  style={{background:"#16243E"}}>
                  <div className="breadcrumb__bg"  data-background="/assets/img/breadcrumb/bg.png"></div>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                              <h3 className="breadcrumb__title">Blog</h3>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                           <div className="breadcrumb__list text-center text-sm-end">
                              <span><Link to="/">{t("textHome")}</Link></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span><Link to="/blog">Blog</Link></span>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
         </section>

         <section className="tp-postbox-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                     <div className="tp-postbox-wrapper">
                        {/*Diffusion d'Information et Financement des PMEs
                        <article className="tp-postbox-item mb-50">
                           <div className="tp-postbox-thumb p-relative">
                              <Link to="">
                                 <img src="/assets/img/blog/postbox/img-1.jpg" alt=""/>
                              </Link>
                              <div className="tp-postbox-thumb-date text-center">
                                 <span>19</span>
                                 <p>May</p>
                              </div>
                           </div>
                           <div className="tp-postbox-content">
                              <div className="tp-postbox-meta">
                                 <span><i className="fa-regular fa-user"></i> By Admin</span>
                                 <span><Link to="">Comments (05)</Link></span>
                              </div>
                              <h3 className="tp-postbox-title">
                                 <Link to="">Diffusion d'Informations et Financement des PMEs</Link>
                              </h3>
                              <div className="tp-postbox-text">
                                 <p>Le paradoxe de surliquidité et de besoin de financement a pour première cause l’asymétrie d’information. Elle traduit le fait que le demandeur a en sa possession des données sur ses capacités, ses compétences, l’objet réel des fonds demandé et dont le banquier n’a aucune connaissance ou alors une connaissance très imparfaite.</p>
                              </div>
                              <div className="tp-postbox-read-more">
                                 <Link to="" className="tp-postbox-btn"><i className="fa-solid fa-arrow-right"></i> Read More</Link>
                              </div>
                           </div>
                        </article>*/}
                        {/*Inteligence Artificielle et Marché du travail
                        <article className="tp-postbox-item mb-50">
                           <div className="tp-postbox-thumb p-relative">
                              <Link to="">
                                 <img src="/assets/img/blog/postbox/img-3.jpg" alt=""/>
                              </Link>
                              <div className="tp-postbox-thumb-date text-center">
                                 <span>19</span>
                                 <p>June</p>
                              </div>
                           </div>
                           <div className="tp-postbox-content">
                              <div className="tp-postbox-meta">
                                 <span><i className="fa-regular fa-user"></i> By Admin</span>
                                 <span><Link to="">Comments (05)</Link></span>
                              </div>
                              <h3 className="tp-postbox-title">
                                 <Link to="">Intelligence Artificielle et le Marché du Travail</Link>
                              </h3>
                              <div className="tp-postbox-text">
                                 <p>L’IA présente des avantages potentiels pour le développement économique et social de l’Afrique subsaharienne, une région qui compte plus d’un milliard d’habitants, dont la moitié a moins de 25 ans. L’IA pourrait contribuer à améliorer la productivité, la qualité et l’accessibilité des services publics, à renforcer les capacités locales et à créer de nouveaux emplois et secteurs d’activité.</p>
                              </div>
                              <div className="tp-postbox-read-more">
                                 <Link to="" className="tp-postbox-btn"><i className="fa-solid fa-arrow-right"></i> Read More</Link>
                              </div>
                           </div>
                        </article>*/}
                        {/*Inteligence Artificielle et Medecine*/}
                        {blogList.map((blog)=>(
                        <article className="tp-postbox-item mb-50" key={blog.id}>
                           <div className="tp-postbox-thumb p-relative">
                              <Link to="">
                                 <img src={blog.image} alt=""/>
                              </Link>
                              {/*<div className="tp-postbox-thumb-btn">
                                 <a className="play-btn popup-video" href={blog.video}><i className="fa-solid fa-play"></i></Link>
                              </div>*/}
                              <div className="tp-postbox-thumb-date text-center">
                                 <span>{blog.day}</span>
                                 <p>{blog.mth}</p>
                              </div>
                           </div>
                           <div className="tp-postbox-content">
                              <div className="tp-postbox-meta">
                                 <span><i className="fa-regular fa-user"></i> {blog.author}</span>
                                 <span><Link to="">{"Comments " + blog.number}</Link></span>
                              </div>
                              <h3 className="tp-postbox-title" 
                              // onClick={()=>{navigate("/blog_details", {state:{service:blog, serviceList:blogList}})}}
                              >
                                 {/*<Link to="">Intelligence Artificielle et Medecine</Link>*/}
                                 <Link to={`/blog_details/${blog.id}`}>  {blog.title}</Link>
                              </h3>
                              <div className="tp-postbox-text">
                                 <p>{blog.resume}</p>
                              </div>
                              <div className="tp-postbox-read-more" 
                              // onClick={()=>{navigate("/blog_details", {state:{service:blog, serviceList:blogList}})}}
                              >
                                 {/*<Link to="" className="tp-postbox-btn"><i className="fa-solid fa-arrow-right"></i> Read More</Link>*/}
                                 <Link to={`/blog_details/${blog.id}`} className="tp-postbox-btn"><i className="fa-solid fa-arrow-right"></i> Read More</Link>
                              </div>
                           </div>
                        </article>
                        ))}
                        {/*Business Intelligence and SMEs
                        <article className="tp-postbox-item mb-50">
                           <div className="tp-postbox-thumb p-relative">
                              <div className="tp-blog-post-active swiper-container">
                                 <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                       <Link to="">
                                          <img src="/assets/img/blog/postbox/img-4.jpg" alt=""/>
                                       </Link>
                                    </div>
                                    <div className="swiper-slide">
                                       <Link to="">
                                          <img src="/assets/img/blog/postbox/img-3.jpg" alt=""/>
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                              <div className="tp-postbox-thumb-date text-center">
                                 <span>28</span>
                                 <p>August</p>
                              </div>
                              <div className="tp-postbox-nav text-end">
                                 <button type="button" className="tp-blog-prev-1"><i className="fa-regular fa-arrow-left"></i>
                                 </button>
                                 <button type="button" className="tp-blog-next-1"><i className="fa-regular fa-arrow-right"></i>
                                 </button>
                              </div>
                           </div>
                           <div className="tp-postbox-content">
                              <div className="tp-postbox-meta">
                                 <span><i className="fa-regular fa-user"></i> By Admin</span>
                                 <span><Link to="">Comments (05)</Link></span>
                              </div>
                              <h3 className="tp-postbox-title">
                                 <Link to="">Business Intelligence, a Tool for SMEs in Sub-Saharan Africa?</Link>
                              </h3>
                              <div className="tp-postbox-text">
                                 <p>BI can be especially useful for small and medium enterprises (SMEs) in sub-Saharan Africa, a region that hosts 90% of businesses and 80% of jobs on the continent.</p>
                              </div>
                              <div className="tp-postbox-read-more">
                                 <Link to="" className="tp-postbox-btn"><i className="fa-solid fa-arrow-right"></i> Read More</Link>
                              </div>
                           </div>
                        </article>*/}

                        {/* <div className="tp-postbox-pagination">
                           <nav>
                              <ul>
                                 <li>
                                    <Link to="">01</Link>
                                 </li>
                                 <li>
                                    <span className="current">02</span>
                                 </li>
                                 <li>
                                    <Link to="">03</Link>
                                 </li>
                              </ul>
                           </nav>
                        </div> */}
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4">
                     <div className="tp-sidebar-wrapper">
                        <div className="tp-sidebar-widget-search mb-40">
                           <div className="tp-sidebar-widget-content">
                              <div className="tp-sidebar-search">
                                 <form action="#">
                                    <div className="tp-sidebar-search-input">
                                       <input type="text" placeholder="Search"/>
                                       <button type="submit"><i className="far fa-search"></i></button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>

                        <div className="tp-sidebar-widget mb-40">
                           <h3 className="tp-sidebar-widget-title">{t("textBl.cat")}</h3>
                           <div className="tp-sidebar-widget-content">
                              <ul>
                                 <li><Link to=""><i className="fa-solid fa-chevrons-right"></i> {t("textBl.it1")}</Link></li>
                                 <li><Link to=""><i className="fa-solid fa-chevrons-right"></i> {t("textBl.it2")}</Link></li>
                                 <li><Link to=""><i className="fa-solid fa-chevrons-right"></i> {t("textBl.it3")}</Link></li>
                              </ul>
                           </div>
                        </div>

                        <div className="tp-sidebar-widget mb-40">
                           <h3 className="tp-sidebar-widget-title">{t("textBl.rec")}</h3>
                           <div className="tp-sidebar-widget-content">
                              <div className="tp-sidebar-post tp-rc__post">
                                 <div className="tp-rc__post d-flex align-items-center">
                                    <div className="tp-rc__post-thumb mr-25">
                                       <Link to=""><img src="/assets/img/blog/postbox/bi_tools_4.jpg" alt=""/></Link>
                                    </div>
                                    <div className="tp-rc__post-content">
                                       <h3 className="tp-rc__post-title">
                                          <Link to="">Business Intelligence, a tool ...</Link>
                                       </h3>
                                       <div className="tp-rc__post-meta">
                                          <span><i className="fa-regular fa-calendar-days"></i> August 28, 2023</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tp-rc__post d-flex align-items-center">
                                    <div className="tp-rc__post-thumb mr-25">
                                       <Link to=""><img src="/assets/img/blog/postbox/ia_medecine_4.jpg" alt=""/></Link>
                                    </div>
                                    <div className="tp-rc__post-content">
                                       <h3 className="tp-rc__post-title">
                                          <Link to="">Intelligence Artificielle et Medecine</Link>
                                       </h3>
                                       <div className="tp-rc__post-meta">
                                          <span><i className="fa-regular fa-calendar-days"></i> July 19, 2023</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tp-rc__post d-flex align-items-center">
                                    <div className="tp-rc__post-thumb mr-25">
                                       <Link to=""><img src="/assets/img/blog/postbox/ia_travail_4.jpg" alt=""/></Link>
                                    </div>
                                    <div className="tp-rc__post-content">
                                       <h3 className="tp-rc__post-title">
                                          <Link to="">Intelligence Artificielle et le Marché du Travail</Link>
                                       </h3>
                                       <div className="tp-rc__post-meta">
                                          <span><i className="fa-regular fa-calendar-days"></i> June 19, 2023</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="tp-sidebar-work mb-40 p-relative" data-background="/assets/img/blog/postbox/img-8.jpg">
                           <h3 className="tp-sidebar-work-title text-center">{t("textBl.wk")}</h3>
                           <div className="tp-sidebar-work-content text-center">
                              <div className="tp-sidebar-work-icon">
                                 <span><img src="/assets/img/blog/postbox/icon-1.svg" alt=""/></span>
                              </div>
                              <p> {t("textBl.txt")}</p>
                           </div>
                        </div>

                        <div className="tp-sidebar-widget">
                           <h3 className="tp-sidebar-widget-title">Tags</h3>
                           <div className="tp-sidebar-widget-content">
                              <div className="tagcloud">
                                 <Link to="">Artificial Intelligence</Link>
                                 <Link to="">Finance</Link>
                                 <Link to="">Deep Learning</Link>
                                 <Link to="">Business</Link>
                                 <Link to="">Forecasting</Link>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>

        </>
    )
}
export default BlogPage;
