import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import localReducer from "./slices/language";

const store = configureStore({
  reducer: {
    auth: authReducer,
    locale: localReducer
  },
})


export {store};
