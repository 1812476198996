import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ContactPage = () =>{
   const {t} = useTranslation()
   useEffect(()=>{
      document.title = "B.K. Research & Analytics | Contacts"
   },[]);
    return (
        <>

         <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"  style={{background:"#16243E"}}>
                  <div className="breadcrumb__bg"  data-background="/assets/img/breadcrumb/bg.png"></div>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                              <h3 className="breadcrumb__title">Contact</h3>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div className="breadcrumb__content">
                           <div className="breadcrumb__list text-center text-sm-end">
                              <span><a href="index.html">{t("textHome")}</a></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span>Contact</span>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
         </section>

         <section className="tp-contact-breadcrumb-area pt-120 pb-120">
            <div className="container">
               <div className="tp-contact-breadcrumb-box">
                  <div className="tp-contact-breadcrumb-social">
                     {/* <a href="#"><i className="fab fa-facebook-f"></i></a> */}
                     {/* <a href="#"><i className="fab fa-twitter"></i></a> */}
                     <Link to="https://www.linkedin.com/company/b-k-research-analytics/"><i className="fa-brands fa-linkedin"></i></Link>
                  </div>
                  <div className="row">
                     <div className="col-lg-7">
                        <div className="tp-contact-breadcrumb-content">
                           <h3 className="tp-contact-breadcrumb-title">{t("textCont.title")}</h3>
                           <p>{t("textCont.subtitle")} </p>
                           <form id="contact-form" action="/assets/mail.php" method="POST">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="tp-contact-input">
                                       <input name="name" type="text" placeholder={t("textCont.name")}/>
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="tp-contact-input">
                                       <input name="email" type="email" placeholder={t("textCont.email")}/>
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="tp-contact-input">
                                       <textarea name="message" placeholder={t("textCont.message")}></textarea>
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="tp-contact-breadcrumb-btn">
                                       <button type="submit" className="tp-btn">{t("textCont.submit")}</button>
                                       <p className="ajax-response"></p>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                     <div className="col-lg-5">
                        <div className="tp-contact-breadcrumb-wrapper">

                           <div className="tp-contact-breadcrumb-item mb-40 d-flex">
                              <div className="tp-contact-breadcrumb-item-icon">
                                 <span><i className="fa-solid fa-location-dot"></i></span>
                              </div>
                              <div className="tp-contact-breadcrumb-item-content">
                                 <h3 className="tp-contact-breadcrumb-item-title">{t("textCont.address")}</h3>
                                 <a href="https://www.google.com/maps/place/3%C2%B055'18.1%22N+11%C2%B031'50.7%22E/@3.9207773,11.5266847,16.65z/data=!4m7!1m2!2m1!1s128,+rue+5972+etoudi+yaounde+1er!3m3!8m2!3d3.921691!4d11.530759?entry=ttu" target="_blank" rel="noreferrer">128, Rue 5972 Etoudi, Yaoundé 1er</a>
                              </div>
                           </div>

                           <div className="tp-contact-breadcrumb-item mb-40 d-flex">
                              <div className="tp-contact-breadcrumb-item-icon">
                                 <span><i className="fa-solid fa-envelope"></i></span>
                              </div>
                              <div className="tp-contact-breadcrumb-item-content">
                                 <h3 className="tp-contact-breadcrumb-item-title">{t("textCont.mail")}</h3>
                                 <a href="mailto:info@bkresearchandanalytics.com" target="_blank" rel="noreferrer">info@bkresearchandanalytics.com</a>
                                 <a href="mailto:contact@bkresearchandanalytics.com" target="_blank" rel="noreferrer">contact@bkresearchandanalytics.com</a>
                              </div>
                           </div>

                           <div className="tp-contact-breadcrumb-item d-flex">
                              <div className="tp-contact-breadcrumb-item-icon">
                                 <span><i className="fa-solid fa-phone"></i></span>
                              </div>
                              <div className="tp-contact-breadcrumb-item-content">
                                 <h3 className="tp-contact-breadcrumb-item-title">{t("textCont.tel")}</h3>
                                 <a href="tel:620255109">+237 620 255 109 </a>
                                 <a href="tel:696888241">+237 696 888 241 </a>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <div className="tp-contact-map-area map-margin">
            <div className="tp-contact-map">
               <iframe src="https://www.google.com/maps/place/3%C2%B055'18.1%22N+11%C2%B031'50.7%22E/@3.9207773,11.5266847,16.65z/data=!4m7!1m2!2m1!1s128,+rue+5972+etoudi+yaounde+1er!3m3!8m2!3d3.921691!4d11.530759?entry=ttu" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
         </div>

        </>
    )
}
export default ContactPage;
