import ReactGA from 'react-ga4';
const percentageFormater = (number)=>{
    const lang = "fr-FR"
    const formater = new Intl.NumberFormat(lang,{
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    return number>1?formater.format(number/100):formater.format(number);
  }
  const curencyFormater =(amount) =>{ 
    const lang = "fr-FR";
    const formater = new Intl.NumberFormat(
        lang, 
        {
            style: 'currency',
            currency: 'XAF',
        }
    )
    return formater.format(amount);
  }

  const validateEmail=(email)=>{
    // const domain = email.split("@")[1];
    // return email && domain !== "yahoo.com" && domain !== "gmail.com" && domain !== "hotmail.com" && domain !== "yahoo.fr" && domain !== "msn.com"
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validatePhoneNumber = (phone) =>{
    const hasUpperCase  =  /[A-Z]+/.test(phone);
    const hasLowerCase  =  /[a-z]+/.test(phone);
    const hasNumeric  =  /[0-9]+/.test(phone);
    return phone  && phone.length>8 && !hasUpperCase && !hasLowerCase && hasNumeric;
  }

  // Google analytics tracking button
  const GAActionTrackingFunction = (action) =>{
    ReactGA.event({
      category: "User",
      action: action
    })
  }

  export {percentageFormater, curencyFormater, validateEmail, validatePhoneNumber, GAActionTrackingFunction}