export const URLS = {
    PARAM_URL: process.env.REACT_APP_PARAM_URL,
    PAYMENT_URL: process.env.REACT_APP_PAYMENT_URL,
    PAYMENT_APP:{
        PRODUCTS: {
            all: () => "products/",
            get: (id) => `products/${id}/`
        },
        PAYMENT: {
            all:() => "payment/",
            get:(id)=> `payment/${id}/`,
            get_status:(id)=>`payment/${id}/get_status`
        },
        CUSTOMER: {
            create:()=>"customers/"
        }
    },
    SUBSCRIBTION: {
        all: () => "subscribtion/",
        create: (packageId) => `subscribtion${packageId}`,
    },
    PARAMS: {
        COUNTRY: {
            list: (search, page) => `/countries/countries/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
            listAll: (limit) => `/countries/countries/?limit=${limit}`,
            african: (limit) => `countries_params/countries/?limit=${limit}`,
            ohada: (limit) => `countries_params/countries/ohada/`
        }
    }
}