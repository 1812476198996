import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
const PrivacyPage = () =>{
  //const navigate = useNavigate();
  const {t} = useTranslation()
   useEffect(()=>{
      document.title = "B.K. Research & Analytics | Terms & Conditions"
   },[]);
    return (
        <>

         <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"  style={{background:"#16243E"}}>
                  <div className="breadcrumb__bg"  data-background="/assets/img/breadcrumb/bg.png"></div>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-6">
                           <div className="breadcrumb__content">
                              <h3 className="breadcrumb__title">{t("textPrivacyPolicy")}</h3>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div className="breadcrumb__content">
                           <div className="breadcrumb__list text-center text-sm-end">
                              <span><Link to="/">{t("textHome")}</Link></span>
                              <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                              <span><Link to="/privacy">{t("textPrivacyPolicy")}</Link></span>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
         </section>

         <section className="tp-service-details-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-service-details-wrapper" style={{textAlign:"justify"}}>
                        <h3 className="tp-service-details-title">{t("TERMS_CONDITIONS.title")}</h3>
                        <p>{t("TERMS_CONDITIONS.lastUpdate")}</p>
                        <p>{t("TERMS_CONDITIONS.section1")}</p>
                        <p>{t("TERMS_CONDITIONS.section2")}</p>

                        
                        <div className="tp-service-details-box-2 d-flex mb-40">
                           <div className="tp-service-details-box-content mr-30">
                              <h3 className="tp-service-details-subtitle-2">Cookies</h3>
                              <p>{t("TERMS_CONDITIONS.cookies1")}</p>
                              <p>{t("TERMS_CONDITIONS.cookies2")}</p>
                           </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">{t("TERMS_CONDITIONS.pluginsTitle")}</h3>
                             <p>{t("TERMS_CONDITIONS.pluginsDesc")}</p>
                             <h4 className="tp-service-details-subtitle-3">{t("TERMS_CONDITIONS.pluginsX")}</h4>
                             <p>{t("TERMS_CONDITIONS.pluginsX1")}</p>
                             <div className="tp-service-widget-list-content">
                             </div>
                             <h4 className="tp-service-details-subtitle-3">{t("TERMS_CONDITIONS.pluginsLk")}</h4>
                             <p>{t("TERMS_CONDITIONS.pluginsLk1")}</p>

                             <h4 className="tp-service-details-subtitle-3">{t("TERMS_CONDITIONS.pluginsInsta")}</h4>
                             <p>{t("TERMS_CONDITIONS.pluginsInsta1")}</p>

                              <h4 className="tp-service-details-subtitle-3">{t("TERMS_CONDITIONS.pluginsFB")}</h4>
                              <p>{t("TERMS_CONDITIONS.pluginsFB1")}</p>

                             <h3 className="tp-service-details-subtitle-2">{t("TERMS_CONDITIONS.license")}</h3>
                             <p>{t("TERMS_CONDITIONS.license1")}</p>
                             <div className="tp-service-widget-list-content">
                                 <ul>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license2")}</li>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license3")}</li>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license4")}</li>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license5")}</li>
                                 </ul> 
                              </div>
                              <p className="mt-4">{t("TERMS_CONDITIONS.license6")}</p>
                             <div className="tp-service-widget-list-content">
                                 <ul>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license7")}</li>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license8")}</li>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license9")}</li>
                                       <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.license10")}</li>
                                 </ul> 
                              </div>
                              <p className="mt-4">{t("TERMS_CONDITIONS.license11")}</p>
                              </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">{t("TERMS_CONDITIONS.hyperLinking")}</h3>
                             <p className="mt-4">{t("TERMS_CONDITIONS.hyperLinking1")}</p>
                             <div className="tp-service-widget-list-content">
                              <ul>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking2")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking3")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking4")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking5")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking6")}</li>
                              </ul>
                             </div>
                             <p className="mt-4">{t("TERMS_CONDITIONS.hyperLinking7")}</p>
                             <div className="tp-service-widget-list-content">
                              <ul>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking8")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking9")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking10")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking11")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking12")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking13")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking14")}</li>
                              </ul>
                             </div>
                             <p className="mt-4">{t("TERMS_CONDITIONS.hyperLinking15")}</p>
                             <div className="tp-service-widget-list-content">
                              <ul>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking16")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking17")}</li>
                                    <li><i className="fa-light fa-circle-check"></i>{t("TERMS_CONDITIONS.hyperLinking18")}</li>
                              </ul>
                             </div>
                             <p className="mt-4">{t("TERMS_CONDITIONS.hyperLinking19")}</p>
                           </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">{t("TERMS_CONDITIONS.iFrame")}</h3>
                             <p className="mt-4">{t("TERMS_CONDITIONS.iFrame1")}</p>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">{t("TERMS_CONDITIONS.contLiab")}</h3>
                             <p>{t("TERMS_CONDITIONS.contLiab1")}</p>
                          </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <section className="tp-service-details-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-service-details-wrapper">
                        <h3 className="tp-service-details-title">B.K. Research & Analytics Privacy Policy </h3>
                        <p>Last updated: June 21, 2022</p>
                        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service or make a purchase from https://www.bkresearchandanalytics.com (the “Site”), and tells You about Your privacy rights and how the law protects You.</p>

                        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>


                        <div className="tp-service-details-box-2 d-flex mb-40">
                           <div className="tp-service-details-box-content mr-30">
                              <h3 className="tp-service-details-subtitle-2">Interpretation and Definitions</h3>
                              <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                              <p>For the purposes of this Privacy Policy:</p>
                              <div className="tp-service-widget-list-content">
                                <ul>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Application</strong> means the software program provided by the Company used by You on any electronic device, named Credit Risk Rating - SME (CR²-SME) or Financial Forecasting SME (FF-SME) or Capital Budgeting SME (CB-SME) or any other not specified here.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to B.K. Research &amp; Analytics, Rue 5972 Etoudi, 5326 Yaoundé.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Country</strong> refers to:  Cameroon</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Service</strong> refers to the Application or the Website or both.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>Website</strong> refers to B. K. Research &amp; Analytics, accessible from <a href="https://www.bkresearchandanalytics.com" rel="external nofollow noopener noreferrer" target="_blank" >https://www.bkresearchandanalytics.com </a></p></li>
                                    <li><i className="fa-light fa-circle-check"></i><p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></li>
                                </ul>
                              </div>
                           </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Collecting and Using Your Personal Data</h3>
                             <h4 className="tp-service-details-subtitle-3">Personal Data</h4>
                             <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i>Email address;</li>
                                 <li><i className="fa-light fa-circle-check"></i>First name and last name;</li>
                                 <li><i className="fa-light fa-circle-check"></i>Phone number;</li>
                                 <li><i className="fa-light fa-circle-check"></i>Usage Data;</li>
                                 <li><i className="fa-light fa-circle-check"></i>Address, State, Province, ZIP/Postal code, City; and</li>
                                 <li><i className="fa-light fa-circle-check"></i>Unique Identifier Number (UIN).</li>
                             </ul>
                             </div>
                             <h4 className="tp-service-details-subtitle-3">Usage Data</h4>
                             <p>Usage Data is collected automatically when using the Service.</p>
                             <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                             <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                             <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

                             <h4 className="tp-service-details-subtitle-3">Information Collected while Using the Application</h4>
                             <p>While using Our Application, in order to provide features of Our Application, We may collect Information regarding your location.</p>
                             <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
                             <p>You can enable or disable access to this information at any time, through Your Device settings.</p>

                             <h4 className="tp-service-details-subtitle-3">Tracking Technologies and Cookies</h4>
                             <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies</li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot; available at <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" rel="external nofollow noopener noreferrer" target="_blank">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a></li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                             </ul>
                             </div>

                             <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: <a href="https://www.freeprivacypolicy.com/blog/cookies/" target="_blank" rel="noreferrer">Cookies: What Do They Do?</a>.</p>
                             <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>Necessary / Essential Cookies</strong></p>
                                  <p>Type: Session Cookies</p>
                                  <p>Administered by: Us</p>
                                  <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                                 </li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                                  <p>Type: Persistent Cookies</p>
                                  <p>Administered by: Us</p>
                                  <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                                 </li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>Functionality Cookies</strong></p>
                                  <p>Type: Persistent Cookies</p>
                                  <p>Administered by: Us</p>
                                  <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                                 </li>
                             </ul>
                             </div>
                             <p>For more information about the cookies we use and your choices regarding cookies, please contact us.</p>

                             <h4 className="tp-service-details-subtitle-3">Use of Your Personal Data</h4>
                             <p>The Company may use Personal Data for the following purposes:</p>
                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p></li>
                             </ul>
                             </div>
                             <p>We may share Your personal information in the following situations:</p>
                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside</li>
                                 <li><i className="fa-light fa-circle-check"></i><strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.</li>
                             </ul>
                             </div>

                             <h3 className="tp-service-details-subtitle-2">Retention of Your Personal Data</h3>
                             <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                             <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

                             <h3 className="tp-service-details-subtitle-2">Transfer of Your Personal Data</h3>
                             <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                             <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                             <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Disclosure of Your Personal Data</h3>
                             <h4 className="tp-service-details-subtitle-3">Business Transactions</h4>
                             <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                             <h4 className="tp-service-details-subtitle-3">Law enforcement</h4>
                             <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                             <h4 className="tp-service-details-subtitle-3">Other legal requirements</h4>
                             <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i>Comply with a legal obligation</li>
                                 <li><i className="fa-light fa-circle-check"></i>Protect and defend the rights or property of the Company</li>
                                 <li><i className="fa-light fa-circle-check"></i>Prevent or investigate possible wrongdoing in connection with the Service</li>
                                 <li><i className="fa-light fa-circle-check"></i>Protect the personal safety of Users of the Service or the public</li>
                                 <li><i className="fa-light fa-circle-check"></i>Protect against legal liability</li>
                             </ul>
                             </div>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Security of Your Personal Data</h3>
                             <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Children's Privacy</h3>
                             <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                             <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Links to Other Websites</h3>
                             <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                             <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Changes to this Privacy Policy</h3>
                             <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                             <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                             <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                          </div>
                        </div>

                        <div className="tp-service-details-box-2 d-flex mb-40">
                          <div className="tp-service-details-box-content mr-30">
                             <h3 className="tp-service-details-subtitle-2">Contact Us</h3>
                             <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                             <div className="tp-service-widget-list-content">
                             <ul>
                                 <li><i className="fa-light fa-circle-check"></i><p>By email: <a href="mailto:contact@bkresearchandanalytics.com">contact@bkresearchandanalytics.com</a></p></li>
                                 <li><i className="fa-light fa-circle-check"></i><p>By phone number: <a href="tel:222217824">(+237) 222 217 824</a></p></li>
                             </ul>
                             </div>
                          </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section> */}

        </>
    )
}
export default PrivacyPage;
