import {useEffect, useState} from "react";
import {Link,  useParams } from "react-router-dom";
import { blogList } from "../../../data/blogdetails/blog";
import { useTranslation } from "react-i18next";

const BlogdetailsPage = () =>{
    //const {state} = useLocation()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
   //  let {service, blogList} = state;
    const [actualBlog, setActualBlog] = useState(null)
   //  const switchBlog=(blg)=>{
   //     setActualBlog(blg)
   //     console.log("Changin blog")
   //     console.log(actualBlog?.title)
   //  }
   const {t} = useTranslation()
    useEffect(()=>{
      for(let i=0; i<blogList.length; i++){
         if(blogList[i].id==id){
            setActualBlog(blogList[i])
            setLoading(false)
         }
      }
    },[])

    return (
        <>
        {!loading && (
         <>
           <section className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix" style={{background:"#16243E"}}>
              <div className="breadcrumb__bg" data-background="/assets/img/breadcrumb/bg.png"></div>
              <div className="container">
                 <div className="row align-items-center">
                    <div className="col-sm-6">
                       <div className="breadcrumb__content">
                          <h3 className="breadcrumb__title">Blog Details</h3>
                       </div>
                    </div>
                    <div className="col-sm-6">
                       <div className="breadcrumb__content">
                       <div className="breadcrumb__list text-center text-sm-end">
                          <span><Link to="/">{t("textHome")}</Link></span>
                          <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                          <span>Blog Details</span>
                       </div>
                       </div>
                    </div>
                 </div>
              </div>
           </section>

           <section className="tp-postbox-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                     <div className="tp-postbox-wrapper">
                        <article className="tp-postbox-item-wrapper mb-80">
                           <div className="tp-postbox-item-thumb p-relative">
                              <img src={actualBlog?.image2} alt=""/>
                              <div className="tp-postbox-meta2">
                                 <span><i className="fa-regular fa-user"></i> {actualBlog?.author}</span>
                                 <span><i className="fa-regular fa-folder-open"></i> {actualBlog?.category}</span>
                                 <span><i className="fa-regular fa-calendar-days"></i> {actualBlog?.dat}</span>
                                 <span><i className="fa-light fa-comments"></i>{" Comments " + actualBlog?.number}</span>
                              </div>
                           </div>
                           <h3 className="tp-postbox-title2">{actualBlog?.title}</h3>
                           <p>{actualBlog?.description_1_a} </p>

                           <p>{actualBlog?.description_1_b} </p>

                           <div className="tp-postbox-list-wrapper d-flex">
                           {actualBlog?.point.map((pointlist, index)=>(
                              <div className="tp-postbox-list mr-40" key={index}>
                                 <h4 className="tp-postbox-list-title"><i className="fa-regular fa-check"></i> {pointlist.title}</h4>
                                 <p>{pointlist.text}</p>
                              </div>
                           ))}
                           </div>

                           <div className="tp-postbox-blockquote p-relative">
                              <blockquote>
                                 <p>{actualBlog?.higli}</p>
                                 <img src="/assets/img/blog/blog-details/quote.png" alt=""/>
                              </blockquote>
                           </div>

                           <p>{actualBlog?.description_2_a} </p>

                           <p>{actualBlog?.description_2_b} </p>

                           <div className="tp-postbox-share-wrapper">
                              <div className="row">
                                 <div className="col-lg-7 col-md-7">
                                 {actualBlog?.tag.map((taglist, index)=>(
                                    <div className="tp-postbox-share-tags tagcloud" key={index}>
                                       {/*<Link to="">Medecine</Link>*/}
                                       {/*<Link to="">Intelligence Artificielle</Link>*/}
                                       <Link to="">{taglist.name}</Link>
                                    </div>
                                ))}
                                 </div>
                                 <div className="col-lg-5 col-md-5">
                                    <div className="tp-postbox-share-social text-md-end">
                                       <div className="tp-footer-widget-social">
                                          <Link to=""><i className="fab fa-facebook-f"></i></Link>
                                          <Link to=""><i className="fab fa-twitter"></i></Link>
                                          <Link to=""><i className="fa-brands fa-instagram"></i></Link>
                                          <Link to=""><i className="fa-brands fa-pinterest"></i></Link>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </article>

                        {/*<div className="tp-postbox-comment mb-60">
                           <ul>
                              <li>
                                 <div className="tp-postbox-comment-box border-mr p-relative">
                                    <div className="tp-postbox-comment-box-inner d-flex">
                                       <div className="tp-postbox-comment-avater">
                                          <img src="/assets/img/blog/blog-details/img-2.jpg" alt=""/>
                                       </div>
                                       <div className="tp-postbox-comment-content">
                                          <div className="tp-postbox-comment-author d-flex align-items-center">
                                             <h5 className="tp-postbox-comment-name">Stanio lainto</h5>
                                             <p className="tp-postbox-comment-date">October 19, 2023</p>
                                          </div>
                                          <p>ished fact that a reader will be distrol acted bioii the.ished fact that a reader  will be distrol acted laoreet Aliquam </p>
                                          <div className="tp-postbox-comment-reply"><Link to="">REPLY<i className="fa-solid fa-paper-plane"></i></Link></div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li className="children">
                                 <div className="tp-postbox-comment-box border-mr p-relative">
                                    <div className="tp-postbox-comment-box-inner d-flex">
                                       <div className="tp-postbox-comment-avater">
                                          <img src="/assets/img/blog/blog-details/img-3.jpg" alt=""/>
                                       </div>
                                       <div className="tp-postbox-comment-content">
                                          <div className="tp-postbox-comment-author d-flex align-items-center">
                                             <h5 className="tp-postbox-comment-name">Nick Jones</h5>
                                             <p className="tp-postbox-comment-date">October 19, 2023</p>
                                          </div>
                                          <p>ished fact that a reader will be distrol acted bioii the.ished fact  that a reader will be distrol acted laoreet Aliquam </p>
                                          <div className="tp-postbox-comment-reply"><Link to="">REPLY<i className="fa-solid fa-paper-plane"></i></Link></div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li>
                                 <div className="tp-postbox-comment-box p-relative">
                                    <div className="tp-postbox-comment-box-inner d-flex">
                                       <div className="tp-postbox-comment-avater">
                                          <img src="/assets/img/blog/blog-details/img-4.jpg" alt=""/>
                                       </div>
                                       <div className="tp-postbox-comment-content">
                                          <div className="tp-postbox-comment-author d-flex align-items-center">
                                             <h5 className="tp-postbox-comment-name">Don streak</h5>
                                             <p className="tp-postbox-comment-date">October 19, 2023</p>
                                          </div>
                                          <p>ished fact that a reader will be distrol acted bioii the.ished fact that a reader  will be distrol acted laoreet Aliquam </p>
                                          <div className="tp-postbox-comment-reply"><Link to="">REPLY<i className="fa-solid fa-paper-plane"></i></Link></div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>*/}

                        <div className="tp-postbox-reply">
                           <h3 className="tp-postbox-reply-title">Leave a comment</h3>
                           <form id="contact-form" action="/assets/mail.php" method="POST">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="tp-postbox-input">
                                       <input name="name" type="text" placeholder="Your Name"/>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="tp-postbox-input">
                                       <input name="email" type="email" placeholder="Your E-mail"/>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="tp-postbox-input">
                                       <input name="number" type="text" placeholder="Phone Number"/>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="tp-postbox-input">
                                       <input name="text" type="text" placeholder="Subject"/>
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="tp-postbox-input">
                                       <textarea type="text" placeholder="Message"></textarea>
                                    </div>
                                 </div>
                                 <div className="col-md-12">
                                    <div className="tp-postbox-input-btn">
                                       <button type="submit" className="tp-btn">Submit Now</button>
                                       <p className="ajax-response"></p>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4">
                     <div className="tp-sidebar-wrapper">
                        <div className="tp-sidebar-widget-search mb-40">
                           <div className="tp-sidebar-widget-content">
                              <div className="tp-sidebar-search">
                                 <form action="#">
                                    <div className="tp-sidebar-search-input">
                                       <input type="text" placeholder="Search"/>
                                       <button type="submit"><i className="far fa-search"></i></button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>

                        <div className="tp-sidebar-widget mb-40">
                           <h3 className="tp-sidebar-widget-title">Categories</h3>
                           <div className="tp-sidebar-widget-content">
                              <ul>
                                 <li><Link to=""><i className="fa-solid fa-chevrons-right"></i> Artificial Intelligence</Link></li>
                                 <li><Link to=""><i className="fa-solid fa-chevrons-right"></i> Finance</Link></li>
                                 <li><Link to=""><i className="fa-solid fa-chevrons-right"></i> Business</Link></li>
                              </ul>
                           </div>
                        </div>

                        <div className="tp-sidebar-widget mb-40">
                           <h3 className="tp-sidebar-widget-title">Recent Post</h3>
                           <div className="tp-sidebar-widget-content">
                              <div className="tp-sidebar-post tp-rc__post">
                                 <div className="tp-rc__post d-flex align-items-center">
                                    <div className="tp-rc__post-thumb mr-25">
                                       <Link to=""><img src="/assets/img/blog/postbox/img-5.jpg" alt=""/></Link>
                                    </div>
                                    <div className="tp-rc__post-content">
                                       <h3 className="tp-rc__post-title">
                                          <Link to="">Business Intelligence, a tool ...</Link>
                                       </h3>
                                       <div className="tp-rc__post-meta">
                                          <span><i className="fa-regular fa-calendar-days"></i> August 28, 2023</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tp-rc__post d-flex align-items-center">
                                    <div className="tp-rc__post-thumb mr-25">
                                       <Link to=""><img src="/assets/img/blog/postbox/img-6.jpg" alt=""/></Link>
                                    </div>
                                    <div className="tp-rc__post-content">
                                       <h3 className="tp-rc__post-title">
                                          <Link to="">Intelligence Artificielle et Medecine</Link>
                                       </h3>
                                       <div className="tp-rc__post-meta">
                                          <span><i className="fa-regular fa-calendar-days"></i> July 19, 2023</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tp-rc__post d-flex align-items-center">
                                    <div className="tp-rc__post-thumb mr-25">
                                       <Link to=""><img src="/assets/img/blog/postbox/img-7.jpg" alt=""/></Link>
                                    </div>
                                    <div className="tp-rc__post-content">
                                       <h3 className="tp-rc__post-title">
                                          <Link to="">Intelligence Artificielle et le Marché du Travail</Link>
                                       </h3>
                                       <div className="tp-rc__post-meta">
                                          <span><i className="fa-regular fa-calendar-days"></i> June 19, 2023</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="tp-sidebar-work mb-40 p-relative" data-background="/assets/img/blog/postbox/img-8.jpg">
                           <h3 className="tp-sidebar-work-title text-center">Work with us</h3>
                           <div className="tp-sidebar-work-content text-center">
                              <div className="tp-sidebar-work-icon">
                                 <span><img src="/assets/img/blog/postbox/icon-1.svg" alt=""/></span>
                              </div>
                              <p>Send us your candidature at contact@bkresearchandanalytics.com.</p>
                           </div>
                        </div>

                        <div className="tp-sidebar-widget">
                           <h3 className="tp-sidebar-widget-title">Tags</h3>
                           <div className="tp-sidebar-widget-content">
                              <div className="tagcloud">
                                 <Link to="">Artificial Intelligence</Link>
                                 <Link to="">Finance</Link>
                                 <Link to="">Deep Learning</Link>
                                 <Link to="">Business</Link>
                                 <Link to="">Forecasting</Link>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>
         </>
         )
      }


        </>
    )
}
export default BlogdetailsPage;
