export const blogList=[
  // Diffusion Information et financement des PMES - 19 mai
  {
    id:"1",
    dat:"May 19, 2023",
    mth:"May",
    day:"19",
    author:"By Admin",
    category:"Finance",
    comment:[
      {
        text:"",
        author:"",
        image:""
      },
      {
        text:"",
        author:"",
        image:""
      }
    ],
    number:"(00)",
    title:"Diffusion d’informations et Financement des PMEs ",
    resume:"Le paradoxe de surliquidité et de besoin de financement a pour première cause l’asymétrie d’information. Elle traduit le fait que le demandeur a en sa possession des données sur ses capacités, ses compétences, l’objet réel des fonds demandé et dont le banquier n’a aucune connaissance ou alors une connaissance très imparfaite.",
    description_1_a:"En Afrique Subsaharienne, les PMEs constituent comme dans les autres régions du monde la grande majorité – environs 95% - des entreprises et contribuent à environs 40% à la formation du Produit Intérieure Brut. Outre la corruption, le – absence de – financement constitue le frein majeur à leur développement.",
    description_1_b:"Bien que les banques s'intéressent de plus en plus aux PMEs, elles deumeurent sur-liquides et les PMEs le chainon manquant d'octroi de crédit à l'économie. Face à ce paradoxe de surliquidité des banques d’une part, et de manque de financement des PMEs d’autre part, quelques pistes de solutions sont proposées ça et la, dont les principales sont :",
    point:[
      {
        title:"Environnement",
        text:"l’amélioration du système judiciaire, l’assainissement de l’environnement des affaires."
      },
      {
        title:"Information",
        text:"une amélioration de la disponibilité et de la qualité de l’information financière et économique."
      }
    ],
    higli:"Le paradoxe de surliquidité et de besoin de financement a pour première cause l’asymétrie d’information. Sa profondeur a des effets tels que le rationnement de crédit et des taux d’intérêt élevé.",
    description_2_a:"La pérennité de la banque dépend de sa capacité à collecter – bureau de crédit – les données et à exploiter – agences de notation – les données obtenues. Le bureau de crédit est un organe privé ou public qui collecte des données sur les agents économiques ayant déjà bénéficié d’un crédit. Collecter les informations au niveau individuel (banque) est très couteux, le bureau de credit permet donc de réaliser une économie d'echelle. ",
    description_2_b:"Les bureaux de crédit qu’ils soient privé ou public permettent le partage de données sur les emprunteurs entre les prêteurs, ce qui contribue grandement à la réduction de l’asymétrie d’information. Le partage de données entre les prêteurs conduit à une meilleure connaissance des caractéristiques des emprunteurs et donc augmente la capacité de la banque à distinguer un mauvais d’un bon payeur, ce qui à son tour réduit le phénomène de sélection adverse. Le partage d'information permet de rendre un modèle tel que CR²-SME plus robuste, plus stable et plus apte à detecter de mauvais payeur sur le moyen terme.",
    tag:[
      {
        name:"Finance"
      },
      {
        name:"Business"
      }
    ],
    image:"/assets/img/blog/postbox/diff_inf.jpg",
    image2:"/assets/img/blog/postbox/diff_inf_2.jpg",
    image3:"/assets/img/blog/diff_inf_3.jpg",
    video:""
  },
  //IA et Marché du travail  - 19 juin
  {
    id:"2",
    dat:"June 19, 2023",
    mth:"June",
    day:"19",
    author:"By Admin",
    category:"AI ",
    comment:[
      {
        text:"",
        author:"",
        image:""
      },
      {
        text:"",
        author:"",
        image:""
      }
    ],
    number:"(00)",
    title:"Intelligence Artificielle et le Marché du Travail ",
    resume:"L’IA présente des avantages potentiels pour le développement économique et social de l’Afrique subsaharienne, une région qui compte plus d’un milliard d’habitants, dont la moitié a moins de 25 ans. L’IA pourrait contribuer à améliorer la productivité, la qualité et l’accessibilité des services publics, à renforcer les capacités locales et à créer de nouveaux emplois et secteurs d’activité.",
    description_1_a:"L'IA est en plein essor dans le monde entier, grâce aux progrès technologiques, à la disponibilité des données et à la demande croissante de solutions innovantes. Elle présente des avantages potentiels pour le développement économique et social de l'Afrique subsaharienne, une région qui compte plus d'un milliard d'habitants, dont la moitié a moins de 25 ans.",
    description_1_b:"Elle pourrait contribuer à améliorer la productivité, la qualité et l'accessibilité des services publics, à renforcer les capacités locales et à créer de nouveaux emplois et secteurs d'activité.",
    point:[
      {
        title:"Secteur Informel",
        text:"le secteur informel représente plus de 80 % de l'emploi total."
      },
      {
        title:"Education",
        text:"le niveau d'éducation et de compétences est faible."
      }
    ],
    higli:"L'IA pose des défis importants pour le marché du travail en Afrique subsaharienne du fait du poids du secteur Informel et du niveau d'Education.",
    description_2_a:"Pour tirer parti des opportunités et atténuer les risques, il est essentiel que les pays africains développent les infrastructures numériques et l'accès à l'internet.",
    description_2_b:"De plus, ils doivent Renforcer le système éducatif et la formation professionnelle, Encourager l'innovation et l'entrepreneuriat, Réguler l'utilisation éthique et responsable de l'IA.",
    tag:[
      {
        name:"Intelligence Artificielle"
      },
      {
        name:"Emploi"
      }
    ],
    image:"/assets/img/blog/postbox/ia_travail.jpg",
    image2:"/assets/img/blog/blog-details/ia_travail_2.jpg",
    image3:"",
    video:""
  },
  // IA et Medecine  -19 juillet
  {
    id:"3",
    dat:"July 19, 2023",
    mth:"July",
    day:"19",
    author:"By Admin",
    category:"AI",
    comment:[
      {
        text:"",
        author:"",
        image:""
      },
      {
        text:"",
        author:"",
        image:""
      }
    ],
    number:"(00)",
    title:"Intelligence Artificielle et Medecine ",
    resume:"L’IA a le potentiel de transformer la pratique de la médecine en aidant les médecins à diagnostiquer les patients plus précisément, à prévoir leur état de santé futur et à recommander de meilleurs traitements.",
    description_1_a:"L'IA a le potentiel de transformer la pratique de la médecine en aidant les médecins à diagnostiquer les patients plus précisément, à prévoir leur état de santé futur et à recommander de meilleurs traitements.",
    description_1_b:"L'IA en médecine se base sur l'analyse de grandes quantités de données provenant de sources variées, telles que les dossiers médicaux, les examens d'imagerie, les tests génétiques ou les capteurs portables. Ces données sont traitées par des algorithmes, qui sont capables de reconnaître des motifs, de détecter des anomalies, de faire des classifications.",
    point:[
      {
        title:"Prevention",
        text:"L'IA peut aider à anticiper les risques de développer certaines maladies ou complications, en se basant sur des facteurs génétiques, environnementaux ou comportementaux (ex. IBM Watson). "
      },
      {
        title:"Traitement",
        text:"L'IA peut aider à choisir le meilleur traitement pour un patient donné, en tenant compte de ses caractéristiques individuelles, de ses préférences et des données probantes (ex. IBM Watson). "
      }
    ],
    higli:"L'IA en médecine présente des avantages indéniables, tels que l'amélioration de la qualité et de la sécurité des soins, l'optimisation des ressources et des coûts, l'accès à l'information et à l'éducation. ",
    description_2_a:"L'IA doit se conformer aux normes légales et réglementaires en vigueur dans chaque pays ou région. Elle doit également être soumise à une évaluation rigoureuse et indépendante avant d'être déployée.",
    description_2_b:"Toutefois, l'IA en médecine pose également des défis et des limites, tels que l'éthique et la réglementation (le consentement éclairé, la confidentialité, la transparence, la responsabilité et la justice), la fiabilité et la validité ( des résultats fiables et valides explicable et justifiés).  ",
    tag:[
      {
        name:"Intelligence Artificielle"
      },
      {
        name:"Medecine"
      }
    ],
    image:"/assets/img/blog/postbox/ia_medecine.jpg",
    image2:"/assets/img/blog/blog-details/ia_medecine_2.jpg",
    image3:"/assets/img/blog/ia_medecine_3.jpg",
    video:"https://www.youtube.com/watch?v=vyit-1zKsZ4&pp=ygUPQUkgYW5kIG1lZGVjaW5l"

  },
  //BI et PME - 28 aout
  {
    id:"4",
    dat:"August 28, 2023",
    mth:"August",
    day:"28",
    author:"By Admin",
    category:"Business",
    comment:[
      {
        text:"",
        author:"",
        image:""
      },
      {
        text:"",
        author:"",
        image:""
      }
    ],
    number:"(00)",
    title:"Business Intelligence, a Tool for SMEs in Sub-Saharan Africa? ",
    resume:"BI can be especially useful for small and medium enterprises (SMEs) in sub-Saharan Africa, a region that hosts 90% of businesses and 80% of jobs on the continent.",
    description_1_a:"Business intelligence (BI) is the process of collecting, integrating, analyzing and presenting information that supports better decision making in organizations. BI can help businesses gain insights into their performance, customers, competitors, markets and opportunities.",
    description_1_b:"BI can help SMEs overcome their challenges by Improving their efficiency and profitability, •	Enhancing their innovation and competitiveness, Supporting their growth and sustainability.",
    point:[
      {
        title:"SME Oppportunities",
        text:"SME constitute 90% of business and 80% of jobs on the continent."
      },
      {
        title:"SME Challenges",
        text:"SME face challenges such as, low productivity, limited access to finance, high competition, weak infrastructure etc."
      }
    ],
    higli:"However, BI also poses some challenges for SMEs in sub-Saharan Africa, such as	The lack of data availability and quality, The high cost and complexity of BI tools and solutions, The ethical and legal issues.",
    description_2_a:"To leverage the benefits and mitigate the risks of BI, SMEs in sub-Saharan Africa need to Invest in digital infrastructure and internet access, which are prerequisites for using BI.",
    description_2_b:"Then, Develop data literacy and analytical skills, both within their own organizations and among their external partners and stakeholders; Adopt appropriate BI tools and solutions, that suit their specific needs, objectives and capacities.",
    tag:[
      {
        name:"Business"
      },
      {
        name:"Business Intelligence"
      }
    ],
    image:"/assets/img/blog/postbox/bi_tools.jpg",
    image2:"/assets/img/blog/blog-details/bi_tools_2.jpg",
    image3:"/assets/img/blog/bi_tools_3.jpg",
    video:""

  }
]
