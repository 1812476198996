import Select from "react-select";
import { styles } from "../../components/utils/reactSelect";
const MobilePayment = ({phoneValid, setPhoneNumber, countries, validCountry, selectedCountry, setSelectedCountry, loading, t, submit}) => {
    return (
        <div className="payment-form">
            <div className="form-group mb-3">
                {!phoneValid &&<label> <p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.phone")}</p></label>}
                {phoneValid &&<label htmlFor="phoneNumber">{t("FORM.payNum")}<span className="text-danger">*</span></label>}
                <input className="form-control" id="phoneNumber" type="text" placeholder="670234590" name="phoneNumber" onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
            </div>
            <div className="form-group">
                                {!validCountry && <label><p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.country")}</p></label>}
                                {validCountry && <label htmlFor="country">{t("FORM.country")} <span className="text-danger">*</span></label>}
                                <Select className="basic-single"
                                  id="country"
                                  styles={styles}
                                  isSearchable
                                  isDisabled={loading}
                                  inputId="country"
                                  value={selectedCountry}
                                  
                                  isLoading={loading}
                                  onChange={(val)=>{setSelectedCountry(val)}}
                                  options={countries?.map((i)=>{
                                    return {label: t(`txtCountry.${i.code2}`), value: i.id, url: i.url, name:i.name, phone:i.phone, code:i.code2}
                                  }) ?? []}
                                />
            </div>
            <p className="text-danger" style={{fontSize:"8pt", lineHeight:1.5}}><i>{t("FORM.noMobileP")}</i></p>
            <div className="method-desc text-center mt-10">
                <p className="mt-10" style={{fontSize:"8pt", lineHeight:1.5}}>{t("FORM.paymentDesc")}</p>
            </div>
            <div className="col-12 text-center">
                <button className="btn btn-primary  btn-block mt-2 w-100 form-btn" disabled={loading} style={{minHeight:"50px", border: "solid 1px #1d3461"}} onClick={(e)=>submit()}>{t("FORM.processPay")}</button>
            </div>
        </div>
    )
}
export default MobilePayment;