export const teamList=[
  // Kamguia Bomyr
  {
    id:1,
    name:"Bomyr Kamguia",
    position:"Data Science Engineer / Financial Analyst",
    description:"Bomyr is the person in charge of the conception of solutions either application or data science, to address customers issues.",
    telephone:"+237 694 921 255",
    email:"bomyr.kamguia@bkresearchandanalytics.com",
    website:"https://github.com/bomyrk",
    address:"Yaoundé, Cameroon",
    skills:"Bomyr is a graduate of the ESSEC Business School in Douala in Audit and Management Control. He has a Master of Science in Finance from France's EM Lyon Business School. He is also a Data Science Architect certified by Intellipaat in India. He has worked in the finance, telecommunications, manufacturing and retail industries.",
    mainskill:[
      {
        clas:"pursent-1 wow slideInLeft",
        skill:"Analytical Thinking",
        percent:"90%",
        perc:"90"
      },
      {
        clas:"pursent-2 wow slideInLeft",
        skill:"SMEs Environment ",
        percent:"95%",
        perc:"95"
      },
      {
        clas:"pursent-3 wow slideInLeft",
        skill:"Problem-Solving ",
        percent:"35%",
        perc:"35"
      }
    ],
    otherskill:[
      "Machine Learning",
      "Financial Modelling",
      "Hadoop Ecosystem (Pig, Hive...)",
      "Visualization (Power BI, Tableau ...)"
    ],
    social:"Bomyr is an introspective guy who enjoys nature and is devoted to his friends. He is quick to share his expertise, which is why he enjoys teaching.",
    linkedin:"https://www.linkedin.com/in/leonel-bomyr-kamguia-wabo-52a79330",
    facebook:"",
    instagram:"",
    twitter:"https://x.com/BKRAnalytics?s=20",
    image:"/assets/img/team/img-1.jpg"

  },
  // Ronald Ndayou
  {
    id:2,
    name:"Ronald Ndayou",
    position:"Software and Data Engineer",
    description:"Ronald is the person in charge of the company's application products' design, development, and production. He also works on the implementation of Data Science solutions for our customers.",
    telephone:"+237 693 775 081",
    email:"ronald.ndayou@bkresearchandanalytics.com",
    website:"https://www.bkresearchandanalytics.com",
    address:"Douala, Cameroon",
    skills:"Ronald is a graduate of the University of Douala in Computer Science and the Ecole Normale de Bamenda in Computer Science. He holds a degree in Computer Science with a Big Data option from the Université 3IL in Limoges. He has experience in the design and development of data-oriented IT solutions.",
    mainskill:[
      {
        clas:"pursent-1 wow slideInLeft",
        skill:"Software Architecture ",
        percent:"90%",
        perc:"90"
      },
      {
        clas:"pursent-2 wow slideInLeft",
        skill:"Big Data ",
        percent:"85%",
        perc:"85"
      },
      {
        clas:"pursent-3 wow slideInLeft",
        skill:"Analytical Thinking ",
        percent:"80%",
        perc:"80"
      }
    ],
    otherskill:[
      "Python ",
      "Versioning",
      "Software development",
      "Big data implementation",
      "Conteneurisation (Docker)"
    ],
    social:"Ronald is a polite and dedicated person. Everything he accomplishes is done with his heart and soul.",
    linkedin:"https://www.linkedin.com/in/ndayou-ronald-vincent-vince",
    facebook:"#",
    instagram:"#",
    twitter:"https://x.com/NRVince?s=20",
    image:"/assets/img/team/img-1.jpg"

  },
  //Elise Wanga
  {
    id:3,
    name:"Elise Wanga",
    position:"Business Operation Coordinator",
    description:"Elise is in charge of administrative management, particularly relations with third parties. She provides operational supervision of the day-to-day running of the business.",
    telephone:"+237 696 888 241",
    email:"elise.wanga@bkresearchandanalytics.com",
    website:"https://www.bkresearchandanalytics.com",
    address:"Yaoundé, Cameroon",
    skills:"Elise trained at the ESSEC Business School of Douala in International Trade and Transport. She also studied Environmental Management and Sustainable Development at IRIC of Yaounde. She has experience in SME management and event organisation.",
    mainskill:[
      {
        clas:"pursent-1 wow slideInLeft",
        skill:"Process Engineering ",
        percent:"90%",
        perc:"90"
      },
      {
        clas:"pursent-2 wow slideInLeft",
        skill:"Customer Relationship ",
        percent:"95%",
        perc:"95"
      },
      {
        clas:"pursent-3 wow slideInLeft",
        skill:"Administrative Management ",
        percent:"85%",
        perc:"85"
      }
    ],
    otherskill:[
      "Accounting",
      "Event Planner",
      "New pedagogies for learning",
      "Customer Relationship ",
      " "
    ],
    social:"Elise loves nature, particularly the ocean. She appreciates music in African languages, especially Duala. She also enjoys watching science documentaries.",
    linkedin:"https://www.linkedin.com/in/elise-ngobo-wanga-45a66941",
    facebook:"#",
    instagram:"#",
    twitter:"https://x.com/EliseWanga?s=20",
    image:"/assets/img/team/img-1.jpg"

  },
  //Herve kamguia
  {
    id:4,
    name:"Herve Feukwi",
    position:"Mathematics and Data Engineer",
    description:"Hervé plays a critical role in the Data Science and Business Intelligence projects that our clients entrust to us. He is especially interested in the modeling aspects.",
    telephone:"+237 690 453 503",
    email:"herve.kamguia@bkresearchandanalytics.com",
    website:"https://www.bkresearchandanalytics.com",
    address:"Yaoundé, Cameroon",
    skills:"Hervé is a graduate of Sup'ptic Yaoundé in Network and Telecommunications. He holds a Master's degree in Applied Mathematics and Computer Science in Medical Diagnosis from St Petersburg University. He has experience in telecommunications (Huawei), IT consulting (TrustWare Cameroon) and the application of deep learning in the medical field.",
    mainskill:[
      {
        clas:"pursent-1 wow slideInLeft",
        skill:"Analytical Thinking",
        percent:"75%",
        perc:"75"
      },
      {
        clas:"pursent-2 wow slideInLeft",
        skill:"Medical Image Processing ",
        percent:"77%",
        perc:"77"
      },
      {
        clas:"pursent-3 wow slideInLeft",
        skill:"Machine Learning ",
        percent:"80%",
        perc:"80"
      }
    ],
    otherskill:[
      "Matlab",
      "Deep Learning Onramp",
      "Data Extraction (SQL)",
      "Data Exploration (Python ...)",
      "Visualization (Power BI ...)"
    ],
    social:"Herve is a friendly person whose company is quickly appreciated. He loves nature and animals. He is involved with associations that help orphans.",
    linkedin:"https://www.linkedin.com/in/kamguia-h-ubuntu",
    facebook:"#",
    instagram:"#",
    twitter:"https://x.com/HerveKamguia?s=20",
    image:"/assets/img/team/img-1.jpg"

  }

]
