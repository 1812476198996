import { Link } from "react-router-dom";
import LangSwitcher from "../lang/langSwitcher";
const PageFooter = () =>{
    return (
        <footer className="tp-footer-area-3 pt-120 p-relative z-index-1" style={{background:"#16243E"}}>
        <div className="tp-footer-bg-shape-3">
           <img className="shape-1" src="/assets/img/footer/home-3/shape-1.png" alt=""/>
           <img className="shape-2" src="/assets/img/footer/home-3/shape-2.png" alt=""/>
        </div>
        <div className="tp-footer-subscribe pb-80">
           <div className="container">
              <div className="tp-footer-subscribe-wrapper">
                 <div className="row align-items-center">
                    <div className="col-lg-5 col-md-7">
                       <div className="tp-footer-subscribe-wrapper-title d-flex align-items-center">
                          <span><img src="/assets/img/footer/home-3/icon.svg" alt=""/></span>
                          <h3 className="tp-footer-subscribe-title">Stay up-to-date! <br/> Subscribe today</h3>
                       </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                       <div className="tp-footer-subscribe-email">
                          <input type="email" placeholder="Your email address"/>
                          <span><i className="fa-solid fa-paper-plane"></i></span>
                       </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                       <div className="tp-footer-subscribe-btn text-lg-end">
                          <button>Subscribe Now</button>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div className="tp-footer-main-area-3">
           <div className="container">
              <div className="tp-footer-border">
                 <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                       <div className="tp-footer-widget tp-footer-3-col-1 mb-50">
                          <div className="tp-footer-logo mb-20">
                          <Link to="/about_us"> <img src="/assets/img/logo/logo_small_no_color.png" alt=""/></Link>
                          </div>
                          <div className="tp-footer-widget-content">
                             <p>For any organization striving for excellence, data ingestion, processing and visualization are critical factors.</p>
                             <div className="tp-footer-info">
                                <div className="tp-footer-info-call">
                                   <a href="tel:696888241"><i className="fa-solid fa-phone"></i>(+237) 696 888 241</a>
                                </div>
                                <div className="tp-footer-info-mail">
                                   <a href="mailto:info@bkresearchandanalytics.com"><i className="fa-solid fa-envelope"></i>info@bkresearchandanalytics.com</a>
                                </div>
                                <div className="tp-footer-info-mail ml-0 mt-5">
                                    <LangSwitcher />
                                 </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                       <div className="tp-footer-widget tp-footer-3-col-2 mb-50">
                          <h3 className="tp-footer-widget-title">Quick Links</h3>
                          <div className="tp-footer-widget-content">
                             <ul>
                                <li><Link to="/about_us"><i className="fa-regular fa-angle-right"></i>About us</Link></li>
                                <li><Link to="/service"><i className="fa-regular fa-angle-right"></i>Services</Link></li>
                                <li><Link to="/team"><i className="fa-regular fa-angle-right"></i>Team</Link></li>
                                <li><Link to="/blog"><i className="fa-regular fa-angle-right"></i>Blog</Link></li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                       <div className="tp-footer-widget tp-footer-3-col-3 mb-50">
                          <h3 className="tp-footer-widget-title">Terms & Conditions</h3>
                          <div className="tp-footer-widget-content">
                             <ul>
                                <li><Link to="/privacy"><i className="fa-regular fa-angle-right"></i>Privacy Policy</Link></li>
                                <li><Link to="/term"><i className="fa-regular fa-angle-right"></i>Terms of Use</Link></li>
                                <li><Link to="/term"><i className="fa-regular fa-angle-right"></i>Copyright</Link></li>
                                <li><Link to="/term"><i className="fa-regular fa-angle-right"></i>Cookies</Link></li>
                             </ul>
                          </div>
                       </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                       <div className="tp-footer-widget tp-footer-3-col-4 mb-50">
                          <h3 className="tp-footer-widget-title">Popular Post</h3>
                          <div className="tp-footer-widget-content">
                             <div className="tp-footer-widget-item">
                                <h4 className="tp-footer-widget-item-title"><Link to = {`/blog_details/1`}>Information et Financement...</Link></h4>
                                <span><i className="fa-regular fa-calendar-days"></i> May 19, 2023</span>
                             </div>
                             <div className="tp-footer-widget-item">
                                <h4 className="tp-footer-widget-item-title"><Link to={`/blog_details/2`}>IA et le Marché du travail </Link></h4>
                                <span><i className="fa-regular fa-calendar-days"></i> June 19, 2023</span>
                             </div>
                             <div className="tp-footer-widget-item">
                                <h4 className="tp-footer-widget-item-title"><Link to = {`/blog_details/3`}>AI and Medecine</Link></h4>
                                <span><i className="fa-regular fa-calendar-days"></i> July 19, 2023</span>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div className="tp-footer-copyright-area p-relative">
           <div className="container">
              <div className="row">
                 <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="tp-footer-copyright-inner">
                       <p>© B.K. Research & Analytics 2023 | All Rights Reserved</p>
                    </div>
                 </div>
                 <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="tp-footer-copyright-inner text-md-end">
                       <Link href="#"><i className="fab fa-facebook-f"></i></Link>
                       <Link href="#"><i className="fab fa-twitter"></i></Link>
                       <Link href="https://www.linkedin.com/company/b-k-research-analytics/"><i className="fa-brands fa-linkedin"></i></Link>
                       {/* <Link href="#"><i className="fa-brands fa-pinterest-p"></i></Link> */}
                    </div>
                 </div>
              </div>
           </div>
        </div>
        </footer>
    );
}

export default PageFooter;
